/* Sort Cards */

.sort-card {
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
  border-radius: 5px;
  position: fixed;
  z-index: 10;
  right: 20px;
  top: 70px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 1rem;
  padding: 0 1rem;
  height: 25px;
  font-size: 0.6875rem;
  line-height: 1.25rem;
  font-weight: bold;
  color: #1B1C1D;
  transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
}

.sort-card > span {
  padding: 7.5px 0;
}

.sort-card > article {
  overflow: hidden;
}

.sort-card .form-group {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1rem;
  align-items: center;
}

.sort-card label {
  align-self: center;
}

.sort-card label:first-child {
  padding-top: 7.5px;
}

.sort-card label:last-child {
  padding-bottom: 3.5px;
}

.sort-card input[type="radio"] {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.2rem;
}

@media only screen and (min-width: 600px) {
  .sort-card {
    right: 40px;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    font-size: 1rem;
    line-height: 1.875rem;
    padding: 0 1rem;
    height: 62px !important;
  } 

  .sort-card .form-group {
    grid-template-columns: repeat(3, auto);
  }

  .sort-card label:first-child {
    padding-top: 0;
  }

  .sort-card label:last-child {
    padding-bottom: 0;
  }
}
.forgot__password__lottie {
  background-color: #efefef;
  padding: 20px;
  border-radius: 50%;
}
.forgot__password__heading {
  color: #1b1c1d;
  font-size: 1.875rem;
  font-family: "Airbnb Cereal App Bold";
}
.forgot__password__sub__heading {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Book";
  margin: 20px 0;
  /* text-align: center; */
}
.change__pass__buttons a {
  color: #1b1c1d;
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Bold";
  text-decoration: none;
}
.forgot__password__button {
  background-color: transparent;
  border: none;
}
.forgot__pass__form__label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--button-color);
  font-size: 1.25rem;
  font-family: "Airbnb Cereal App Bold";
  margin-top: 50px;
}
/* .forgot__password__input{
 width:100%;
} */
.forgot__password__input__container{
    width:100%;
}
.forgot__password__button{white-space: nowrap;}


@media (max-width: 1024px) {
    /* .forgot__password__input__container{
        width:80%;
    } */
    .forgot__password__heading {
        font-size: 1.4rem;
        margin-top: 10px;
      }
      .forgot__password__sub__heading {
        margin: 10px 0;
      }
}

@media (max-width: 767px) {
    .forgot__password__input__container{
        width:100%;
    }
    .forgot__password__home{
        padding:30px 15px;
    }
}
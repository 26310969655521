.leaderboard__giftcard__list__section__details,
.leaderboard__giftcard__list__section {
  display: grid;
  gap: 2rem;
  padding: 5px 0;
  align-items: center;
}

.leaderboard__giftcard__list__section__details {
  grid-template-columns: 1fr auto;
}
.leaderboard__giftcard__list__section {
  grid-template-columns: auto 1fr auto;
  border-radius: 5px;
  margin: 20px;
}

.leaderboard__giftcard__list__section:hover,
#root
  > div
  > div
  > main
  > main
  > div.main-content
  > div
  > div:nth-child(2)
  > div
  > div.content-avatar-tabs
  > div.avatar-content.active-avatar-content
  > section
  > section.my__leaderboard__giftcard__list__main
  > div
  > ul
  > div:nth-child(1) {
  background-color: #efefef;
}

#root > div > div > main > main > div.main-content > div > div:nth-child(2) > div > div.content-avatar-tabs > div.avatar-content.active-avatar-content > section > section.my__leaderboard__giftcard__list__main > div > ul > div:nth-child(1) > div.leaderboard__giftcard__list__section__details > div.leaderboard__giftcard__level__section > p {
  background-color: #1b1c1d;
  color: #fff;
}
.leaderboard__giftcard__level {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  font-size: 1rem;
  border: 1px solid #dadada;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.leaderboard__giftcard__level__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
}
.leaderboard__giftcard__list__section__details__text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.leaderboard__giftcard__list__section__details__text__name {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Book";
}
.leaderboard__list__section__section p {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Bold";
}

.leaderboard__giftcard__list__main {
  height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  /* direction: rtl; */
}

.my__leaderboard__giftcard__list__main {
  height: 100px;
  overflow: hidden;
  width: 100%;
  /* background-color: red; */
  /* direction: rtl; */
}
.leaderboard__giftcard__list__section__details__image {
  width: 80px;
  height: 80px;
}
.leaderboard__giftcard__list__section__details__image img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.leaderboard__giftcard__level {
  font-family: "Airbnb Cereal App Bold";
}

@media only screen and (max-width: 600px) {
  .leaderboard__giftcard__list__section {
    gap: 1rem;
  }
  .leaderboard__giftcard__list__section__details__image {
    width: 55px;
    height: 55px;
  }
  .leaderboard__giftcard__list__section__details__text__name,
  .leaderboard__list__section__section p {
    font-size: 0.625rem;
  }
  .leaderboard__giftcard__level__section {
    gap: 10px;
  }
  .leaderboard__giftcard__level {
    font-size: 1rem;
  }
  #root
    > div
    > div
    > main
    > main
    > div.main-content
    > div
    > div:nth-child(2)
    > div {
    padding: 25px 0px 40px;
  }
  #root
    > div
    > div
    > main
    > main
    > div.main-content
    > div
    > div:nth-child(2)
    > div
    > div:nth-child(1)
    > div
    > div {
    width: 80%;
  }
}

.credentials__main {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 100px 0px;
}
.change__password__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.change__password__icon__text {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  font-size: 1.25rem;
  margin-bottom: -10px !important;
}
/* #root
  > div
  > div
  > main
  > main
  > section
  > div:nth-child(2)
  > div.change__password__icon {
  margin-left: -50px !important;
} */
/* #root > div > div > main > main > section > section > div:nth-child(2) > div.change__password__icon{
  margin-left: -50px !important;
}

#root > div > div > main > main > section > section > div:nth-child(2) > div.change__password__icon > div > div {
  margin-bottom: -5px !important;
} */
#root > div > div > main > main > section > section > div:nth-child(2) > div.change__password__icon > p{
  margin-left: -35px !important;
  margin-top:-3px!important;
}
.change__password {
  padding: 70px 54px;
  background-color: #f8f8f8;
  border: 1px solid #efefef;
  border-radius: 10px;
  /* width: 559px; */
  /* height: 423px; */
  margin: 40px;
}
.change__password__icon__info {
  color: #707070;
  font-family: "Airbnb Cereal App Book";
  font-size: 1rem;
  line-height: 1.8;
  margin: 20px 0;
  max-width: 420px;
}
.change__password__button {
  color: var(--main-white);
  text-align: center;
  font-size: 1rem;
  padding: 18px 27px;
  font-family: "Airbnb Cereal App Book";
  background-color: var(--button-color);
  border-radius: 10px;
  margin-top: 50px;
}
.change__password__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.change__password__footer p {
  color: #a8a8a8;
  max-width: 250px;
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Book";
}
@media (max-width: 991px) {
  .change__password__footer {
    margin-bottom: 6.25rem;
  }
}
@media screen and (max-width: 767px) {
  .credentials__main {
    flex-direction: column;
    padding: 40px 0px 20px;
  }
  .change__password__footer {
    padding-bottom: 50px;
    /* margin-bottom: 6.25rem; */
  }
  .change__password {
    padding: 50px 34px;
    margin: 30px;
  }
  #root
    > div
    > div
    > main
    > main
    > section
    > section
    > div:nth-child(2)
    > div.change__password__icon
    > p,
  #root
    > div
    > div
    > main
    > main
    > section
    > section
    > div:nth-child(2)
    > div.change__password__icon {
    margin-left: -35px;
  }
  .change__password__icon {
    gap: 5px;
  }
  /* #root
    > div
    > div
    > main
    > main
    > section
    > section
    > div:nth-child(2)
    > div.change__password__icon
    > p {
    margin-bottom: 0px !important;
  } */
}

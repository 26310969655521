.profile-form {
  padding: 1rem 0;
}

.profile-form article {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.profile-form .form-group {
  margin-bottom: 2rem;
}

.profile-form label {
  font-family: "Airbnb Cereal App Medium";
  padding: 0.2rem 0.4rem;
  background-color: #FFFFFF;
  position: absolute;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375rem;
  left: 0.8rem;
  top: 0;
  margin-bottom: 0;
  vertical-align: middle;
  opacity: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition-property: top font-size;
  -moz-transition-property: top font-size;
  transition-property: top font-size;
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
  z-index: 2;
}

.profile-form input {
  font-family: "Airbnb Cereal App Medium";
  padding: 0.625rem 0.8rem;
  display: block;
  width: 100%;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #1B1C1D;
  border-radius: 4px;
  border: 1px solid rgb(168, 168, 168);
  background-color: transparent;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
}
 #email{
  text-transform: none!important;
}

.profile-form input:focus {
  outline: none;
  border: 1px solid #6F6F6F;
}

.profile-form input::-webkit-input-placeholder {
  opacity: 0;
  visibility: hidden;
}

.profile-form input:-ms-input-placeholder {
  opacity: 0;
  visibility: hidden;
}

.profile-form input:valid {
  _background-color: #FFFFFF;
}

.profile-form .btn-container {
  text-align: right;
}

.profile-form .btn-container button {
  background-color: #1B1C1D;
  color: #ffffff;
  padding: 0.875rem 1.75rem;
}

.profile-form .btn-container button:hover,
.profile-form .btn-container button:focus {
  background-color: #FFFFFF;
  color: #1B1C1D;
}

@media only screen and (min-width: 992px) {
  .profile-form input {
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0.75rem 0.8rem;
  }
}
.main--section {
  display: none !important;
  overflow: hidden !important;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
}

.main__section__image img {
  width: 100%;
  height: 100%;
}
/* max-width: 31.25rem;

display: none;
_-webkit-transform: translateX(-100%);
_-moz-transform: translateX(-100%);
_transform: translateX(-100%);
_-webkit-transition: transform 0.5s ease-out;
_-moz-transition: transform 0.5s ease-out;
_transition: transform 0.5s ease-out; */

@media (min-width: 1200px) {
  .main--section {
    background: var(--primary--color);
    display: block !important;
    min-height: 100vh;
    /* flex-basis: 50%;
    overflow: hidden !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important; */
  }
  /* .main__section__image{
    width: 800px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  } */
  .authwrapper {
    width: 100%;
    height: 100%;
    display: flex;
    height: 100vh;
    overflow: hidden;
    align-items: center;
    position: relative;
  }
  /* .main--section img {
    width: 100%;
    height: 100%;
    margin-top: 100px;
  } */
}

@media (min-width: 768px) {
  .main--section {
    background: var(--primary--color);
  }
  /* .main--section img {
    width: 100%;
    height: 100%;
    margin-top: 100px;
  } */
  .main__section__image {
    width: 700px;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

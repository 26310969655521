.quick-seller {
  _flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  margin: 1rem 0;
  font-family: "Airbnb Cereal App Bold";
}
.capsule__link,
.capsule__link:hover,
.capsule__link:visited{
  text-decoration: none;
  color:#1B1C1D;
}
.giftcardhistory-board {
  _flex: 3;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.giftcardhistory-header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  line-height: 1.875rem;
  border-bottom: 1px solid #efefef;
  padding-bottom: 1rem;
}
.giftcardhistory-header h3 {
  font-size: 1.25rem;
  font-weight: bold;
  flex-basis: 40%;
  font-family: "Airbnb Cereal App Bold";
}
.giftcardhistory--body {
  display: flex;
  gap: 100px;
}
.giftcardhistory-header button {
  padding: 1rem;
  font-size: 0.875rem;
  background-color: #f8f8f8;
  border-radius: 10px;
  border: none;
  flex-basis: 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Airbnb Cereal App Bold";
  height: 43px;
}
.giftcardhistory-board-history {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.giftcardhistory-board-history-text {
  font: normal normal normal 1.25rem Airbnb Cereal App Book;
  color: #a8a8a8;
}

.giftcardhistory-item {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 0 3rem;
  padding: 1.25rem;
  border-bottom: 1px solid #efefef;
}

.giftcardhistory-store {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: bold;
  color: rgb(27, 28, 29);
  font-family: "Airbnb Cereal App Medium";
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.giftcardhistory-store p:last-child {
  font-size: 1rem;
  color: rgb(168, 168, 168);
  font-weight: normal;
}

.giftcardhistory-country p {
  font-size: 1rem;
  line-height: 1.375rem;
  color: rgb(27, 28, 29);
  font-weight: bold;
  font-family: "Airbnb Cereal App Bold";
}

.giftcardhistory-icon-wrapper {
  width: 100%;
}
.giftcardhistory-list {
  border-right: 1px solid #efefef;
}
.custom--card {
  border: 1px solid #efefef;
  background-color: #ffffff;
  padding: 1.2rem 1.2rem 0;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.giftcard-history-header {
  display: flex;
  justify-content: space-between;
  max-width: 88%;
  margin: 0 auto;
  margin-bottom: -20px;
  padding: 0 0.5rem;
}

.giftcard-history-header-rank {
  margin-top: 20px;
  font-size: 1.75rem;
  line-height: 1.875rem;
  font-weight: bold;
}
.giftcard-history-lottie,
.giftcard-history-button {
  margin-left: 10px;
}
.giftcard-history-button .btn {
  padding: 0.375rem !important;
  white-space: nowrap;
}
/* .giftcard-history-button .btn:hover{
background-color: #1B1C1D;
}
.giftcard-history-button a:hover
{
  color: #fff!important;
} */
.giftcard-history-button a {
  color: #1b1c1d !important;
  text-decoration: none !important;
}

.quick {
  display: block;
  white-space: nowrap;
}
.giftcard_transaction_quick {
  font-size: 1.25rem;
}
.quick_span {
  display: none;
}
.history-icon-wrapper{
  width: 35px;
  height: 35px;

}
.history-icon-wrapper img{
  height: 100%;
  width: 100%;
}

@media (min-width: 601px) {
  .quick {
    display: none;
  }
  .quick_span {
    display: block;
  }
  .giftcard-history-header {
    display: flex;
    justify-content: flex-start;
  }
  .history-icon-wrapper{
    width: 45px;
    height: 45px;
  
  }
}

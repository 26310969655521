* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
:root {
  --primary--color: #000;
  --secondary-color: #fff;
  --extra-color: #878788;
  --button-color: #1b1c1d;
  --bg-color: rgba(255, 255, 255, 0.5);
  --p-color: #868593;
  --off-white: #d6d6d6;
  --p-white: #838383;
  --off-color: #00000029;
  --other-color: rgba(0, 0, 0, 0.3);
  --nav-color: rgba(0, 0, 0, 0.2);
  --menu-color: rgb(252, 252, 252);
  --sub-color: #ccc;
  --hover-color: rgb(219, 219, 219);
  --wrapper-color: #efefef;
  --slider-color: rgb(134, 133, 147);
  --video-color: #f6f6f6;
  --header-color: rgb(189, 189, 202);
  --main-white: #ffffff;
  --main-grey: #6f6f6f;
  --border-color: #232323;
  --grey-color: #a8a8a8;
  --border-border: #d3d3d3;
}
/* Browser Default Reset */

h1,
h2,
h3,
h4,
h5,
h6 {
  overflow: hidden !important;
}

@font-face {
  font-family: "Airbnb Cereal App Book";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Book"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBook.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Light";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Light"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealLight.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Medium"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealMedium.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Bold"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBold.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Extra Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Extra Bold"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealExtraBold.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Black";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Black"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBlack.woff")
      format("woff");
}
/* 
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
} */
.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: gold;
  color: #fff;
}
.alert-primary {
  background: darkolivegreen;
  color: #fff;
}
.alert-light {
  background: blue;
  color: #fff;
}
.alert-dark {
  background: coral;
  color: #fff;
}
.alert-danger {
  background: red;
  color: #fff;
}
.alert-success {
  background: green;
  color: #fff;
}
.hide--on--desktop {
  display: block !important;
}
.left--section {
  display: none !important;
}
.child {
  box-shadow: 0px 0px 3px var(--off-color);
  min-height: 90vh;
  flex-basis: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 30px 40px;
  position: relative;
}

form {
  width: 80%;
}

.right--section--h2 {
  text-align: center;
  font: normal normal bold 22px Airbnb Cereal App Bold;
  color: var(--secondary--color);
  opacity: 1;
  line-height: 40px;
  /* margin-bottom: 1rem; */
}

.right--section--p {
  text-align: center;
  font: normal normal normal 14px Airbnb Cereal App Book;
  color: var(--grey-color);
  opacity: 1;
  white-space: nowrap;
  margin-bottom: 1rem !important;
}
.redirect-to-signup {
  margin-top: 15px;
}
.redirect-to-signup p {
  font-size: 12px;
  font-family: Airbnb Cereal App Book;
}
.redirect-to-signup a {
  color: red;
  text-decoration: none;
}
.signin-footer-links > ul > a:nth-child(n) {
  text-decoration: none;
  color: #000;
  font-family: Airbnb Cereal App Book;
}
/* .log--button{
  position: relative;
} */
.signin-footer-links-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  white-space: nowrap;
}
.signin-footer-links-ul-p {
  font-size: 14px;
  font-family: Airbnb Cereal App Book;
}
.signin-footer-links {
  /* position: absolute;
  bottom: 0; */
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding-bottom: 10px;
  margin-top: 50px;
}
.signin-footer-links-ul li {
  margin-right: 10px;
  font-size: 12px;
}

.form-container {
  position: relative;
}
.form-container .signin-form-control::placeholder {
  position: absolute;
  top: 20px;
  left: 25px;
  color: var(--main-grey) !important;
  font-size: 16px !important;
  font-family: Airbnb Cereal App Medium;
  pointer-events: none;
  font-weight: medium;
}
.signin-form-control:focus {
  outline: none !important;
  border: 1px solid var(--grey-color) !important;
}
.signin-form-control {
  display: block;
  width: 100%;
  outline: none !important;
  height: 62px !important;
  margin: 1.5rem 0 1rem !important;
  padding: 1.5rem !important;
  color: var(--main-grey) !important;
  font-size: 16px !important;
  font-family: Airbnb Cereal App Medium;
  font-weight: medium;
  line-height: 1.5;
  background-color: var(--main-white);
  background-clip: padding-box;
  border: 1px solid var(--border-border);
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.signin-form-control:hover {
  border: 1px solid var(--grey-color) !important;
}

.signin-form-control:focus {
  outline: none !important;
  border: 1px solid var(--grey-color) !important;
}
#password {
  position: relative;
}
.field-icon {
  right: 5px;
  top: 25px;
  position: absolute;
  z-index: 2;
  padding-right: 15px !important;
  color: black !important;
}

.form-check .form-check-input {
  margin-top: 4px;
}
.form-check-label {
  color: var(--secondary--color);
  font: normal normal normal 14px Airbnb Cereal App Book;
}

.form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}

.forgot-password a,
.forgot-password a:visited,
.forgot-password a:hover,
.forgot-password a:active {
  color: var(--secondary--color);
  font: normal normal normal 14px Airbnb Cereal App Bold;
  text-decoration: none;
}
.link-button {
  text-decoration: none;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.log--button,
.sign--in--button {
  border: 2px solid var(--primary--color);
  border-radius: 10px;
  margin-top: 50px;
  /* padding: 10px; */
  height: 50px;
  /* width: 45%; */
  width: 100%;
  background-color: var(--primary--color);
  color: var(--secondary-color);
 
  text-align: center;
  font: normal normal bold 14px/22px Airbnb Cereal App Bold;
}
.loading__button{
  border: 2px solid var(--primary--color);
  border-radius: 10px;
  margin-top: 50px;
  /* padding: 10px; */
  height: 50px;
  /* width: 45%; */
  width: 100%;
  background-color: transparent;
  text-align: center;
}
.log--button:hover,
.sign--in--button:hover {
  color: var(--secondary--color);
  background-color: var(--main-white);
}
.user--icon--container {
  width: 50px;
  height: 50px;
}
.user--icon--container img {
  width: 100%;
  height: 100%;
}
.checker--forgot--password {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.redirect__to__landing__page {
  position: absolute;
  top: -7px;
  left: 10px;
  width: 100px;
  height: 80px;
}
.redirect__to__landing__page img {
  width: 100%;
  height: 100%;
}
@media (min-width: 1200px) {
  .child {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    padding: 0px 100px;
  }
}

@media (max-width: 1024px) {
  .right--section--p {
    white-space: normal;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .user--icon--container {
    width: 80px;
    height: 80px;
  }
  .redirect__to__landing__page {
    width: 120px;
    height: 100px;
  }
  .right--section--h2 {
    font: normal normal bold 32px Airbnb Cereal App Bold;
  }
  .right--section--p {
    font: normal normal normal 22px Airbnb Cereal App Book;
  }
  .checker--forgot--password {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  /* 
  .form-container .input-span {
    position: absolute;
    top: 30px;
    left: 25px;
    color: var(--main-grey) !important;
    font-size: 20px !important;
    font-family: Airbnb Cereal App Medium;
    pointer-events: none;
    font-weight: medium;
  } */
  .signin-form-control {
    font-size: 22px !important;
    height: 72px !important;
    margin: 3rem 0 2rem !important;
    padding: 2.5rem !important;
  }

  .field-icon {
    right: 15px;
    top: 35px;
  }
  #content-wrap
    > div
    > section
    > div
    > div.col-sm-12.col-md-6.col-lg-6.d-flex.justify-content-center.align-items-center.text-center.right--section
    > div
    > form
    > div.checker--forgot--password.d-flex.justify-content-between.align-items-center.text-center {
    margin-top: 40px;
  }
  .form-check .form-check-input {
    margin-top: 4px;
  }
  .form-check-input {
    width: 2em;
    height: 2em;
    margin-right: 5px;
  }
  .form-check-label,
  .forgot-password a {
    font: normal normal normal 20px Airbnb Cereal App Bold;
  }
  .log--button,
  .loading__button,
  .sign--in--button {
    font: normal normal bold 24px Airbnb Cereal App Bold;
    height: 60px;
  }
}
@media (max-width: 411px) {
  .form-check .form-check-input {
    margin-top: 4px;
  }
}
@media (max-width: 375px) {
  .form-check .form-check-input {
    margin-top: 4px;
  }
}
@media (max-width: 360px) {
  .right--section--h2 {
    margin-bottom: 0px;
  }
}

@media (max-width: 320px) {
  .right--section--h2 {
    margin-bottom: 0px;
  }
  .right--section--p {
    white-space: normal;
  }
}
@media (max-width: 280px) {
  .signin-form-control {
    height: 42px !important;
  }
  .field-icon {
    right: 5px;
    top: 15px;
  }
  .form-check-label,
  .forgot-password a,
  .forgot-password a:visited,
  .forgot-password a:hover,
  .forgot-password a:active {
    text-decoration: none;
    font: normal normal normal 12px Airbnb Cereal App Book;
    white-space: nowrap;
  }

  .sign--in--button,
  .log--button,
  .loading__button {
    height: 40px;
  }
}

/* .otpVerification{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
} */
.otpVerification__heading {
}
.otpVerification_subText {
  text-align: center;
  font: normal normal normal 14px Airbnb Cereal App Book;
  color: var(--grey-color);
  opacity: 1;
  overflow-wrap: break-word;
  margin-bottom: 1rem !important;
}
.cantFindAuthorization {
}
.authorization_email {
  font-weight: bold;
  color:#212529;
}
.verify_otp_button {
  margin-top: 20px;
  color: var(--main-white);
  text-align: center;
  font-size: 1rem;
  padding: 10px 35px;
  font-family: "Airbnb Cereal App Bold";
  background-color: var(--button-color);
  border-radius: 10px;
}
.otpVerification {
  box-shadow: 0px 0px 3px var(--off-color);
  min-height: 90vh;
  flex-basis: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 30px 40px;
  position: relative;
  padding: 50px 20px;
}
@media (min-width: 1200px) {
  .otpVerification {
    width: 45%;
    height: 100%;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    padding: 100px;
  }
}

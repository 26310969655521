.transfer__section__main {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 60px 100px;
  border: 1px solid #efefef;
  border-radius: 3px;
  padding: 40px;
  /* height: 100vh; */
}

@media (max-width: 900px) {
  .transfer__section__main {
    margin: 40px 40px;
    padding: 40px;
  }
}
@media (max-width: 500px) {
  .transfer__section__main {
    margin: 40px 40px;
    padding: 40px 10px 20px;
  }
}

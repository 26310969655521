.profile-wrapper {
  max-width: 88%;
  margin: 60px auto 0;
}

.profile-content {
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 10px;
  max-width: 624px;
  width: 100%;
}

.profile-header .btn-container,
.profile-header p,
.profile-form {
  margin: 0 auto;
  width: 93%;
}

.profile-content button {
  font-family: "Airbnb Cereal App Medium";
  padding: 0.625rem 1.25rem;
  border: 1px solid #1B1C1D;
  border-radius: 10px;
  font-size: 0.75rem;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* Profile Modal */

.profile-wrapper .modal_container {
  position: absolute;
  background-color: rgba(27, 28, 29, 0.302);
  height: 100vh;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-wrapper  .modal_container.show_modal {
  opacity: 1;
  visibility: visible;
}

.profile-wrapper ._modal {
  width: 90%;
  min-height: 50vh;
  background-color: #232527;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 15px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.profile-wrapper .modal_header button {
  background-color: transparent;
  border: none;
  outline: none;
}

.profile-wrapper .modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #ffffff;
}

.profile-wrapper .modal_header h4 {
  font-size: 1rem;
  line-height: 1.875rem;
}

.profile-wrapper .modal_content {
  text-align: center;
  padding: 3rem 0;
}

.profile-wrapper .modal_content img {
  max-width: 30%;
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.profile-wrapper .modal_footer {
  background-color: #1B1C1D;
  padding: 0.5rem 1rem;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/* End of profile Modal */

@media only screen and (min-width: 600px) {
  .profile-header .btn-container,
  .profile-header p,
  .profile-form {
    width: 80%;
  }

  .profile-wrapper ._modal {
    width: 75%;
  }

  .profile-wrapper .modal_header {
    padding: 2rem;
  }

  .profile-wrapper .modal_header h4 {
    font-size: 1.25rem;
  }

  .profile-wrapper .modal_footer {
    padding: 0.5rem 2rem;
  }
}

@media only screen and (min-width: 992px) {
  .profile-content button {
    font-size: 0.875rem;
    line-height: 1.375rem;
    padding: 0.625rem 1.5rem;
  }

  .profile-wrapper ._modal {
    width: 60%;
  }
}
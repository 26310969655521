.airtime__data__main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.airtime__data__first__section {
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 10px;
}
.airtime__data__first__section__text {
  color: #1b1c1d;
  font-family: Airbnb Cereal App, Book;
  font-size: 0.75rem;
}

@media (min-width: 992px) {
  .airtime__data__first__section__text {
    font-size: 1rem;
  }
  .airtime__data__main {
    margin: 80px auto 40px;
  }
}

.house {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 0.8rem;
  padding: 1.5rem 1rem;
  cursor: pointer;
  width: 320px;
}
.airtime__transaction__body {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.8rem;
  padding: 1rem;
  align-items: center;
}

.accordion-item {
  margin-bottom: 30px;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px !important;
}
.accordion-content {
  display: flex;
  flex-direction: column;
}
.house__title{
  text-align: left;
  margin-left:10px;
}
.house-title,
.airtime__transaction__sub__body__provider {
  font-size: 0.75rem;
  font-family: "Airbnb Cereal App Bold";
  color: #1b1c1d;
}
.airtime__transaction__sub__body__provider {
  margin-bottom: 0 !important;
}
.airtime__transaction__body__image {
  width: 35px;
  height: 35px;
}
.house__image {
  width: 25px;
  height: 25px;
}
.airtime__transaction__body__image img,
.house__image-image {
  width: 100%;
  height: 100%;
}
.airtime__transaction__sub__body__transaction {
  color: #6f6f6f;
  font-size: 0.75rem;
  font-family: "Airbnb Cereal App Book";
}

.airtime__transaction__body__link,
.airtime__transaction__body__link:hover,
.airtime__transaction__body__link:visited {
  text-decoration: none;
  color: #1b1c1d;
}
.airtime__transaction__sub__body{
  text-align: left;
  margin-left: 5px;
}

@media (min-width: 992px) {
  .accordion {
    width: 60%;
  }
  .house {
    width: auto;
  }
  .house-title,
  .airtime__transaction__sub__body__provider {
    font-size: 1rem;
  }
  .house__image {
    width: 40px;
    height: 40px;
  }
  .house__title{
    margin-left:20px;
  }
  .airtime__transaction__sub__body{
    text-align: left;
    margin-left: 30px;
  }
}

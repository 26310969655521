.redem__avatar__main {
  position: relative;
  display: grid;
  width: 100%;
  /* height:100%; */
  grid-template-columns: 1fr 400px;
  margin: 70px auto 30px;
  border: 1px solid #efefef;
}
.redem__avatar__avatar__section {
  border: 1px solid #efefef;
  background-color: #fff;
  height: 100%;
}
.redem__avatar__avatar__section > div:first-child {
  background-image: url("../../../asset/Mask\ Group\ 46.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 8.875rem;
  padding: 0.625rem 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.avatar_img-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -5%);
  width: 140px;
  height: 140px;
}
.avatar_img-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: none;
}
.avatar-profile {
  text-align: center;
  padding: 1rem 0 1rem;
}
.avatar-profile p {
  font-family: "Airbnb Cereal App Medium";
  color: #1b1c1d;
  font-size: 0.875rem;
}
.avatar-profile__section {
  margin-top: 120px;
  padding: 30px 0;
  text-align: center;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.next-avatar-rank .next-avatar-rank__span {
  position: relative;
  width: 40%;
  margin: 10px auto;
}
/* .next-avatar-rank span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20%;
  background-color: #459f45;
} */
.avatar-profile__section .next-avatar-rank__span {
  background-color: #efefef;
  border-radius: 12px;
  overflow: hidden;
  display: block;
  height: 0.1875rem;
}
.avatar-profile__section__level {
  color: #a8a8a8;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Book";
}
.avatar-profile__section__level__name {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Medium";
}
.avatar-profile__section__second {
  padding: 30px 0;
}
.avatar-profile__section__button__section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-profile__section__button {
  color: var(--main-white);
  text-align: center;
  font-size: 1rem;
  padding: 10px 30px;
  font-family: "Airbnb Cereal App Book";
  background-color: var(--button-color);
  border-radius: 10px;
  margin-top: 45px;
  cursor: pointer;
}
.avatar-profile__loading__button{
  /* border: 2px solid var(--primary--color); */
  border-radius: 10px;
  margin-top: 45px;
  padding: 10px 30px;
  /* padding: 10px; */
  /* height: 50px */
  width: 45%;
  /* width: 100%; */
  background-color: transparent;
  text-align: center;
}

@media only screen and (max-width: 1400px) {
  .redem__avatar__main {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 992px) {
  .redem__avatar__main {
    margin: 40px auto 30px;
  }
}
@media only screen and (max-width: 768px) {
  #root
    > div
    > div
    > main
    > main
    > div.main-content
    > div
    > div:nth-child(1)
    > div
    > div:nth-child(1)
    > div
    > div {
    width: 100%;
  }
}

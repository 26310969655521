.forgot__password__input {
  position: relative;
}
.forgot__password__toggle {
  right: 5px;
  top: 110px;
  position: absolute;
  z-index: 2;
  padding-right: 15px !important;
  color: black !important;
}
.err{
  color: red;
}
@media (min-width: 768px) {
  .forgot__password__toggle {
    right: 15px;
    top: 110px;
  }
}

@media (max-width: 280px) {
  .forgot__password__toggle {
    right: 5px;
    top: 110px;
  }
  .second__forgot__pasword__toggle{
    right: 5px;
    top: 140px;
  }
}

.all-rates-calculator {
  padding-left: 0;
  padding-right: 0;
  border: none;
}

.rates-content {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 2rem;
  min-height: 75vh;
}

.rates-left {
  flex-basis: 50%;
}

.rates-left form {
  width: 100%;
  margin: 0 auto;
}

.rates-left .countries {
  padding: 2rem 0;
  _position: relative;
}

.rates-left .countries h4,
.rates-left label {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-family: "Airbnb Cereal App Bold";
}

.country-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.country-list button {
  text-transform: uppercase;
  flex: 1;
  padding: 0.5rem 0.2rem;
  border: 1px solid #d3d3d3;
  border-left: none;
  background-color: #ffffff;
  font-family: "Airbnb Cereal App Medium";
}

.country-list button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #d3d3d3;
}

.country-list button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0.55rem 0;
}

.rates-left .dropdown-container {
  position: absolute;
  z-index: 10;
  top: 0;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  border: 1px solid #d3d3d3;
}

.rates-left .show-dropdown {
  opacity: 1;
  visibility: visible;
}

.rates-left .dropdown {
  list-style: none;
  background-color: #ffffff;
  text-align: center;
  margin-bottom: 0;
}

.rates-left .dropdown-item {
  border-bottom: 0.5px solid #a8a8a8;
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  /* width: 55px; */
}

.dropdown-item:hover {
  background-color: #a8a8a8;
}

.rates-left .form-group {
  position: relative;
}

.rates-left .denomination select {
  width: 100%;
  display: block;
  padding: 0.625rem 1.25rem 0.625rem 0.8rem;
  border-radius: 4px;
  border: 1px solid rgb(112, 112, 112);
  appearance: none;
  -webkit-appearance: none;
  color:#1b1b1b;
  font-family: "Airbnb Cereal App Medium";
}

.rates-left .denomination img {
  position: absolute;
  right: 0.7rem;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.rates-left > p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0;
  width: 100%;
  margin: 0 auto;
  font-weight: bold;
}

.rates-left > p span:first-child {
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: #a8a8a8;
  font-family: "Airbnb Cereal App Book";
}

.rates-left > p span:last-child {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #1b1b1b;
  font-family: "Airbnb Cereal App Bold";
}

.rates-right {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 6.25rem;
}

.rates-right form {
  width: 100%;
  margin: 0 auto;
  order: 3;
}

.rates-right .form-group {
  position: relative;
  margin-top: 1rem;
}

.rates-right .form-group label {
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #6f6f6f;
}

.rates-right label.input_placeholder {
  padding: 0.4rem;
  background-color: #ffffff;
  position: absolute;
  font-weight: 400;
  left: 0.8rem;
  top: 50%;
  margin-bottom: 0;
  vertical-align: middle;
  opacity: 0.5;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition-property: top font-size;
  -moz-transition-property: top font-size;
  transition-property: top font-size;
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
  _z-index: -1;
}

.rates-right .img-wrapper {
  width: 35px;
  height: 35px;
  margin: 0.5rem auto;
  border: 1px solid #efefef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
  order: 2;
}

input.currency-input {
  padding: 0.625rem 0.8rem;
  display: block;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(112, 112, 112);
  background-color: transparent;
  position: relative;
  z-index: 1;
  font-family: "Airbnb Cereal App Bold";
}

::placeholder {
  font-family: "Airbnb Cereal App Bold";
  color: #1b1c1d;
}

.currency-input:focus {
  outline: none;
  border: 1px solid #6f6f6f;
}

.currency-input::-webkit-input-placeholder {
  opacity: 0;
  visibility: hidden;
}

.currency-input:focus + label.input_placeholder {
  top: 0;
  opacity: 1;
  font-size: 0.75rem;
  line-height: 0.75rem;
  z-index: 2;
}

.currency-input:valid {
  background-color: #ffffff;
}

.rates-right .currency-equivalent {
  padding: 1rem 0;
  order: 1;
}

.rates-right .currency-equivalent h4 {
  width: 100%;
  margin: 2rem auto 0;
  text-transform: capitalize;
  font-size: 0.7rem;
  line-height: 1.375rem;
  font-weight: bold;
  color: #a8a8a8;
  font-family: "Airbnb Cereal App Bold";
}

.rates-right .currency-equivalent p {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: bold;
  font-family: "Airbnb Cereal App Bold";
}

.currency-equivalent p::before,
.currency-equivalent p::after {
  content: "";
  flex: 1;
  height: 1px;
  background-color: rgb(112, 112, 112);
}

.currency-equivalent p::before {
  margin-right: 1.5rem;
}

.currency-equivalent p::after {
  margin-left: 1.5rem;
}

.currency-equivalent p span:first-child {
  color: #459f45;
}

@media only screen and (min-width: 601px) {
  .currency-input::-webkit-input-placeholder {
    opacity: 1;
    visibility: visible;
  }
  /* .rates-left .dropdown-item {
    width: 65px;
  } */
}

@media only screen and (min-width: 768px) {
  .rates-right {
    margin-bottom: 0;
  }
  .all-rates-calculator {
    border: 1px solid #efefef;
  }

  .rates-left form {
    width: 80%;
  }

  .rates-left > p {
    width: 80%;
  }

  .rates-left > p span:first-child {
    font-size: 1rem;
  }

  .rates-left > p span:last-child {
    font-size: 1.125rem;
    line-height: 1.425rem;
  }

  .rates-left .countries h4,
  .rates-left label {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  .rates-right form {
    order: 1;
    width: 80%;
  }

  .rates-right .form-group {
    margin-top: 0;
  }

  .rates-right .form-group label {
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #1b1b1b;
  }

  .rates-right .img-wrapper {
    width: 60px;
    height: 60px;
  }

  .rates-right .currency-equivalent {
    order: 3;
    padding: 1rem 0 2.5rem;
  }

  .rates-right .currency-equivalent h4 {
    margin: 0 auto 3rem;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #1b1b1b;
    width: 80%;
  }

  .rates-right .currency-equivalent p {
    font-size: 2.5rem;
  }

  .currency-equivalent p::before {
    margin-right: 3rem;
  }

  .currency-equivalent p::after {
    margin-left: 3rem;
  }
}

@media only screen and (min-width: 1110px) {
  .rates-content {
    flex-direction: row;
    padding: 2rem 0 3rem;
  }

  .rates-left {
    border-right: 1px solid rgb(112, 112, 112);
  }
}

.card-upload {
  padding: 0 10px;
  color: #1b1b1b;
  margin: 1rem auto 1rem;
  width: 100%;
  max-width: 750px;
  margin-bottom: 10.25rem;
}


.card-upload h4 {
  font-size: 1rem;
  line-height: 1.875rem;
  font-family: "Airbnb Cereal App Bold";
  margin-bottom: 0!important;
  /* font-weight: bold; */
}
.gift-item-header-image{
  width: 30px;
  height: 30px;
}
.gift-item-header-image img{
  width: 100%;
  height: 100%;
}
.upload_first-header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;
  padding: 0.5rem 0 1rem;
}

.trade-summary {
  border: 2px solid #c2c2c2;
  border-radius: 4px;
  padding: 0.75rem 1rem;
}

.trade-summary {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-bottom: 0.5rem;
}

.summary-content {
  grid-row: 2 / 3;
}

.summary-content p {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem !important;
}

.summary-content p span {
  flex: 1;
}

.summary-content p span:first-child,
.summary-content p span:last-child {
  font-size: 0.75rem;
  line-height: 1.375rem;
}

.summary-content p span:first-child {
  /* font-weight: 600; */
  color: #a8a8a8;
  font-family: "Airbnb Cereal App Medium";
}

.summary-content p span:last-child {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  /* font-weight: bold; */
}

.summary-content p:first-child > span:last-child {
  text-transform: uppercase;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.summary-content .country-img-wrapper {
  width: 10%;
  display: inline-block;
  margin-left: 0.5rem;
}

.card-upload .card-img-wrapper {
  padding: 1rem 0;
  align-self: center;
  justify-self: center;
  grid-row: 1 / 2;
  /* width: 106px;
  height: 63px; */
}
.giftcard__summary{
  width:106px;
  height:93px;
}
.giftcard__summary img{
  width: 100%;
  height: 100%;
}
.card__details__con {
  width: 132px;
  height: 79px;
}
.card__details__con img {
  width: 100%;
  height: 100%;
}

/* Giftcard Upload / Code input */

.upload_second-header__right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.upload_second-header__right_p {
  font-size: 0.875rem;
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Book";
}
.upload_second-header__right__sub_p {
  font-size: 0.875rem;
  color: #6c63ff;
  font-family: "Airbnb Cereal App Book";
}
.upload_second-header__right__sub {
  display: flex;
  align-items: center;
  margin-left: 0px;
  cursor: pointer;
}
.upload_second-header__right__sub_image {
  margin-top: -10px;
  width: 20px;
  height: 20px;
}
.upload_second-header__right__sub_image img {
  width: 100%;
  height: 100%;
}

.upload_second-header {
  padding: 1rem 0 0.5rem;
  /* margin-bottom: 0!important; */
  /* display: flex!important; */
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.upload-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
}


.card-upload .img-upload-container,
.card-upload .code-input-wrapper {
  border: 2px solid #c2c2c2;
  border-radius: 4px;
  padding: 1rem;
}

.card-upload .img-upload-container {
  text-align: center;
}

.img-upload-container .img-wrapper {
  margin-bottom: 0.5rem;
}

.giftcard__upload__mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  flex-wrap: wrap;
  border: 1px dashed #d3d3d3;
  border-radius: 4px;
  padding: 20px 0;
  /* padding: 30px 240px; */
}
.giftcard__upload__mobile img {
  margin: 10px;
}

.mobile__uploaded__image {
  position: relative;
  /* margin: 10px; */
}
.mobile-close-image {
  position: absolute;
  border: none;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  color: #fff;
  background-color: red;
  margin-left: -30px;
  margin-top: 5px;
}
.mobile-close-image span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
}
.start__trade__button__section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  /* width: min(80vw, 600px); */
  margin: 0 auto 0;
}
.start__trade__button {
  color: #fdfdfd;
  text-align: center;
  font-size: 1rem;
  padding: 15px 35px;
  font-family: "Airbnb Cereal App Book";
  background-color: #1b1c1d;
  border-radius: 10px;
  margin-top: 25px;
  border: 1px solid transparent;
}

.card-upload .img-upload-container label {
  text-align: center;
  display: block;
}

.img-upload-container input[type="file"] {
  padding: 0;
  display: inline-block;
  max-width: 60px;
  color: transparent;
  border: none;
}

.img-upload-container input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.img-upload-container input[type="file"]::before {
  content: "browse";
  display: inline-block;
  background-color: transparent;
  padding-left: 0.25rem;
  color: #6c63ff;
  font-size: 1rem;
  line-height: 1.375rem;
  cursor: pointer;
  outline: none;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
}

.img-upload-container input[type="file"]:hover::before,
.img-upload-container input[type="file"]:active::before {
  color: rgba(105, 97, 255, 0.8);
}

/* Card upload for small screen */

.smallscreen_card-upload {
  display: grid;
  grid-template-columns: 35% 1fr;
  align-items: center;
  border: 1px dashed #d3d3d3;
  padding: 0.75rem;
  border-radius: 4px;
  /* background-image: linear-gradient(to right, #d3d3d3 50%, #fff 50%),
    linear-gradient(to right, #d3d3d3 50%, #fff 50%),
    linear-gradient(to bottom, #d3d3d3 50%, #fff 50%),
    linear-gradient(to top, #d3d3d3 50%, #fff 50%);
  background-position: top, bottom, left, right;
  background-size: 18px 1.5px, 17px 1.5px, 1.5px 17px, 1.5px 16px;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y; */
}

.smallscreen_card-upload p {
  color: #1b1c1d;
}

.card-upload .upload-content > p {
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #1b1c1d;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
}

.card-upload .upload-content > p::before,
.card-upload .upload-content > p::after {
  content: "";
  flex: 1;
  background-color: #d3d3d3;
  height: 0.5px;
}

.card-upload .upload-content > p::before {
  margin-right: 1rem;
}

.card-upload .upload-content > p::after {
  margin-left: 1rem;
}

.card-upload .upload-input {
  color: transparent;
  border: none;
  padding: 0;
}

.upload-input::-webkit-file-upload-button {
  visibility: hidden;
}

.upload-input::before {
  content: url("../../../asset/upload-dummy.svg");
  cursor: pointer;
  display: inline-block;
  height: 1.5rem;
  vertical-align: middle;
  user-select: none;
  -webkit-user-select: none;
  outline: none;
  position: relative;
  z-index: 2;
}

.card-upload .form-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.upload-content input {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #dadada !important;
}

.main__ecode__details__section__form h3 {
  padding: 5px 0;
  /* width: min(80vw, 600px); */
  margin: auto;
  display: flex;
  align-items: center;
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Medium";
  font-size: 1rem;
}
.main__tags-input-container {
  border: 1px solid #d3d3d3;
  padding: 10px;
  border-radius: 5px;
  /* width: min(80vw, 600px); */
  margin-top:0.7em;
  /* margin: 0.5em auto 1em; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}
.main__tags-input {
  flex-grow: 1;
  padding: 0.5em;
  border: none;
  outline: none;
  background-color: transparent;
  color: #1b1c1d;
}
.main__tags-input::placeholder {
  color: #1b1c1d;
}
.main__ecode__details__section__button__section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  /* width: min(80vw, 600px); */
  margin: 0.5em auto 1em;
}
.main__ecode__details__section__button {
  color: #FDFDFD;
  text-align: center;
  font-size: 1rem;
  padding: 15px 35px;
  font-family: "Airbnb Cereal App Book";
  background-color:#1B1C1D;
  border-radius: 10px;
  margin-top: 25px;
  border: 1px solid transparent;
}
.main__ecode__details__section__form__sub{
  border: 1px solid #DADADA;
  padding:  15px 10px;
}

@media only screen and (min-width: 540px) {
  .summary-content .country-img-wrapper {
    width: 8%;
  }
}

@media only screen and (min-width: 600px) {
  .card-upload h4 {
    font-size: 1.25rem;
  }
  .upload_second-header {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
  }
  .upload_second-header__right {
    flex-direction: row;
    align-items: center;
  }
  .upload_second-header__right__sub {
    margin-left: 5px;
  }
  .trade-summary {
    grid-template-columns: 1fr auto;
  }

  .summary-content,
  .card-upload .card-img-wrapper {
    grid-row: 1 / 2;
  }

  .summary-content {
    grid-column: 1 / 2;
  }

  .card-upload .card-img-wrapper {
    grid-column: 2 / 3;
  }

  .summary-content p span:first-child,
  .summary-content p span:last-child {
    font-size: 1rem;
  }
  .uploaded__image {
    position: relative;
    margin: 10px;
  }
  .close-image {
    position: absolute;

    border: none;
    /* top: 0;
    right: 0; */
    border-radius: 50%;
    height: 20px;
    width: 20px;
    color: #fff;
    background-color: red;
    margin-left: -30px;
  }
  .close-image span {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: -3px;
  }

  /* .upload-content {
    grid-template-columns: 1fr auto;
  } */

  .card-upload .img-upload-container p,
  .card-upload .img-upload-container label {
    font-size: 1rem;
    line-height: 1.375rem;
    color: #a8a8a8;
    font-family: "Airbnb Cereal App Book";
    /* font-weight: 600; */
  }
}

@media only screen and (min-width: 681px) {
  .card-upload .img-upload-container label {
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-family: "Airbnb Cereal App Book"; */
  }
}
@media only screen and (min-width: 992px) {
  .main__ecode__details__section__form__sub{
    padding:  25px;
  }
  .card-upload {
    margin-bottom: 0;
  }
  
}

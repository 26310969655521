.main {
  _flex-basis: 80%;
  width: 100%;
}

.main-content {
  max-width: 88%;
  margin: 40px  auto ;
}

/* #root > div > div > main > main > header > div.second-header-text > div.headache, */
/* #root > div > div > main > main > header > div.second-header-text > div.headache,
#root > div > div > main > div > div > main > header > div.second-header-text > div.headache,
#root > div > div > main > div > main > header > div.second-header-text > div.headache
#root > div > div > main > main > main > header > div.second-header-text > div.headache
{
  display: none;
} */

.transaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.875rem;
  border-bottom: 1px solid #efefef;
  margin: 1rem 1.2rem;
  padding-bottom: 1rem;
}
.transaction-body {
  padding: 1rem;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #efefef;
}

.transaction-header h3 {
  font-size: 1.25rem;
  font-weight: bold;
  font-family: "Airbnb Cereal App Medium";
  white-space: nowrap;
}

.transaction-header button {
  padding: 0.4rem 0.8rem;
  font-size: 0.875rem;
  background-color: #f8f8f8;
  border-radius: 10px;
  border: none;
  flex-basis: 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Airbnb Cereal App Medium";
  height: 43px;
}

.transaction-title {
  font: normal normal bold 1rem Airbnb Cereal App Bold;
  color: #1b1c1d;
}
.transaction-bank {
  font: normal normal normal 0.875rem Airbnb Cereal App Book;
  color: #a8a8a8;
}
.transaction_left_side {
  margin-left: 15px;
}
.transaction-amount {
  margin-left: 20px;
  font: normal normal bold 1rem Airbnb Cereal App Bold;
  color: #1b1c1d;
}
.transaction_right_side {
  margin-right: 100px;
}
.transaction-date {
  font: normal normal normal 1rem Airbnb Cereal App Book;
  color: #1b1c1d;
}
.transaction-time {
  font: normal normal normal 0.875rem Airbnb Cereal App Book;
  color: #a8a8a8;
}
.banklogo {
  width: 48px;
  height: 48px;
}
.banklogo img {
  width: 100%;
  height: 100%;
}
.check,
.checkfail {
  width: 15px;
}
.transaction-footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
}

.transaction-footer p {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: medium;
  text-decoration: underline;
  color: rgb(27, 28, 29);
}
.transaction__mobile {
  display: none;
}
.transaction__mobile__main {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.8rem;
  padding: 5px 0;
  margin:12px 0 5px;
}
.transaction__mobile__main__transaction__icon {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}
.transaction-body__mobile {
  padding: 1rem;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #efefef;
}
.transaction__mobile__main__image{
  width:50px;
  height:50px;
}
.transaction__mobile__main__image img{
  width:100%;
  height:100%;
}

@media screen and (max-width: 767px) {
  .transaction__desktop {
    display: none;
  }
  .transaction__mobile {
    display: block;
    margin-bottom: 6.25rem;
  }
  .transaction__mobile__main__image{
    width:40px;
    height:40px;
  }

}

.gift-box {
  _flex: 2;
  display: grid;
  grid-template-rows: auto 1fr;
  margin-top: 30px;
  padding: 0.5rem 0.8rem;
  margin-bottom: 6.25rem;
  /* margin-bottom: 1rem; */
}

.trade-value {
  display: flex;
  justify-content: space-between;
}

.reward {
  padding: 1rem 0;
}

.next-rank {
  padding-top: 1rem;
}

.trade-value .reward__span {
  background-color: #1B1C1D42;
  border-radius: 12px;
  overflow: hidden;
  display: block;
  height: 0.1rem;
  position: relative;
}

.trade-value p {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-family: "Airbnb Cereal App Book";
}

.next-rank .reward__span {
  position: relative;
}

/* .next-rank span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10%;
  background-color: #1B1C1D;
} */

.giftbox-img-wrapper {
  width: 20%;
  height: 20%;
}

.trade-value .amt {
  font-size: 1rem;
  line-height: 1.375rem;
  color: rgb(27, 28, 29);
}

.gift-box-footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 1rem;
}

.custom-card-footer .gift-box-footer a {
  text-decoration: underline;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

@media only screen and (min-width: 1100px) {
  .gift-box {
    margin-bottom: 0;
  }
}
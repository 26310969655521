.bank-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 150px 0px 0px;
  /* height:100vh; */
}
.bank-icon {
  padding: 10px;
}
.bank-banner {
  background-image: url("./../../asset/Group 163290.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100px;
  position: relative;

}
.bank__banner__div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}
.bank__banner__div img {
  width: 100%;
  height: 100%;
}
.bank-details__header {
  color: var(--main-grey);
  font-size: 1.25rem;
  /* padding: 20px; */
  font-family: "Airbnb Cereal App Bold";
  text-align: center;
}
.bank-details__sub-header {
  color: var(--grey-color);
  font-size: 1.25rem;
  padding: 15px 20px;
  font-family: "Airbnb Cereal App Book";
  max-width: 521px;
  text-align: center;
}
.bank-details__button {
  color: var(--main-white);
  text-align: center;
  font-size: 1rem;
  padding: 20px 30px;
  font-family: "Airbnb Cereal App Book";
  background-color: var(--button-color);
  border-radius: 10px;
  margin-top: 25px;
}
.add-bank__button {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.card-body__bank-details {
  padding: 30px 120px;
}
.bank-details-add-bank {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root > div > div > main > main > div {
  min-height: 0;
}
/* .bank-banner{
    width:100%;
} */
.bank-banner-card {
  padding: 0px !important;
  max-width: 674px;
}
/* .form-groupp{
    margin-top:10px;
} */

#root
  > div
  > div
  > main
  > main
  > section
  > div
  > div
  > div:nth-child(1)
  > div.form-group
  > img {
  z-index: 1;
}
.form-groupp .account,
.disabled-account,
#selectbank {
  width: 100%;
  display: block;
  padding: 0.625rem 1.25rem 0.625rem 0.8rem;
  border-radius: 5px;
  border: 1px solid rgb(112, 112, 112);
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  color: #1b1b1b;
  font-family: "Airbnb Cereal App Medium";
  height: 60px;
}
.disabled-account,
.account-label {
  margin: 10px 0px 0px;
}

.account-label,
#root
  > div
  > div
  > main
  > main
  > section
  > div
  > div
  > div.form-groupp
  > div.label-wrapper
  > label {
  color: var(--button-color);
  font-size: 1.25rem;
  font-family: "Airbnb Cereal App Bold";
  /* text-align: center; */
}
.addbank__last-section {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  max-width: 600px;
  gap: 80px;
  padding: 30px 0px 50px;
}
.addbank__last-section__footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
}
.addbank__last-section__footer p {
  color: var(--grey-color);
  font-size: 0.875rem;
}

@media only screen and (max-width: 768px) {
  .bank-details {
    padding: 50px 0px 0px;
  }
  .bank-details__header {
    font-size: 1rem;
  }
  .bank-details-add-bank {
    padding: 30px;
    margin-bottom: 50px;
  }
  .card-body__bank-details {
    padding: 30px !important;
  }
  .addbank__last-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .form-groupp .account,
  .disabled-account,
  #selectbank {
    height: 50px;
  }
}
@media only screen and (min-width: 768px) {
  .card-img-top {
    height: 10.625rem;
  }
  .bank-banner {
    height: 150px;
  }
  .bank-banner-card {
    width: 674px;
  }
}

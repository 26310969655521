.help__section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 90%;
  margin: 70px auto 100px;
}

.help__section__first__card,
.help__section__second__card,
.help__section__third__card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 370px;
  height: 450px;
  margin: auto;
  border-radius: 5px;
  cursor: pointer;
  /* position: relative; */
}

.help__section__first__card {
  background-color: #b8b3fe;
}
.help__section__second__card {
  background-color: #fff5c6;
}
.help__section__third__card {
  background-color: #efefef;
}
.help__section__image {
  /* position: absolute; */
  width: 254px;
  height: 130px;
  /* top: 100px; */
}

.help__section__image img {
  width: 100%;
  height: 100%;
}
.help__section__title {
  padding: 30px 25px 0px;
}
.help__section__title h1 {
  font-size: 1.75rem;
  font-family: "Airbnb Cereal App Bold";
  color: #1b1c1d;
  margin-bottom: 15px;
}
.help__section__title p {
  font-size: 1.25rem;
  font-family: "Airbnb Cereal App Bold";
  color: #1b1c1d;
}
.help__section__sub__section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: #f8f8f8;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 85%;
  margin: 0px auto 70px;
}
.help__support {
  display: flex;
  margin: auto;
  gap: 20px;
  padding: 25px 10px;
  /* justify-content: space-between; */
}
.help__support__title__title {
  font-size: 1rem;
  font-family: "Airbnb Cereal App Bold";
  color: #1b1c1d;
}
.help__support__title__handle {
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Bold";
  color: #6c63ff;
}
.help__support__image {
  width: 50px;
  height: 50px;
}
.help__support__image img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .help__section {
    grid-gap: 0px;
    margin: 60px auto 100px;
  }
  .help__section__image {
    width: 200px;
    height: 100px;
  }
  .help__section__first__card,
  .help__section__second__card,
  .help__section__third__card {
    width: 170px;
    height: 350px;
    /* width: 320px;
    height: 400px; */
  }
  .help__support__image {
    width: 40px;
    height: 40px;
  }
  .help__support {
    flex-direction: column;
    align-items: center;
    height: 180px;
  }
  .help__support__title {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }
  .help__section__sub__section {
    margin: 0px auto 100px;
  }
  .help__section__sub__section {
    width: 90%;
  }
  .help__support__title__title {
    font-size: 0.875rem;
  }
  .help__support__title__handle {
    font-size: 0.75rem;
  }
  .help__section__title h1 {
    font-size: 1.25rem;
  }
  .help__section__title p {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 991px) and (min-width: 769px) {
  .help__section__first__card,
  .help__section__second__card,
  .help__section__third__card {
    width: 280px;
    height: 390px;
  }
}
@media only screen and (min-width: 992px) {
  .help__section__first__card,
  .help__section__second__card,
  .help__section__third__card {
    width: 220px;
    height: 390px;
  }
}
@media only screen and (max-width: 500px) {
  .help__section {
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
    margin: 40px auto 100px;
  }
  .help__section__first__card,
  .help__section__second__card,
  .help__section__third__card {
    width: 320px;
    height: 370px;
  }
  .help__support {
    height: 230px;
  }
}

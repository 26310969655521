* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
ul {
  padding-left: 0 !important;
}
p {
  margin-bottom: 0 !important;
}
.container {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.left-side {
  /* width: 60%; */
  gap: 40px;
}
.left-side_sub {
  gap: 40px;
}
.right-side {
  gap: 20px;
}
/* .second {
  border: 1px solid #efefef;
  height: 300px;
  padding: 1.2rem;
  border: 1px solid #efefef;
  background-color: #ffffff;
  padding: 1.2rem 0.8rem;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
} */

.long {
  width: 860px;
  border: 1px solid #efefef;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.swal2-container {
  z-index: 10000;
}

/* .inner {
  gap: 20px 0px;
} */

/* .fourth {
  height: 250px;

  background: #f8f8f8;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 380px;
  border: 1px solid #efefef;

  padding: 1.2rem 0.8rem;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
} */

/* .fifth {
  height: 250px;

  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px 0;
  width: 380px;
  border: 1px solid #efefef;

  padding: 1.2rem 0.8rem;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
} */

/* .sixth {
  height: 250px;

  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 380px;
  border: 1px solid #efefef;

  padding: 1.2rem 0.8rem;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
} */
.custom-card-body {
  flex: 1 1 auto;
  padding: 0.7rem 1rem;
}

@media (max-width: 1200px) {
  .home-header h1 {
    margin-bottom: -40px !important;
  }
}
/* @media (min-width: 601px) {
  .show-on-mobile {
    display: none !important;
  }
  .withdrawimg img {
    width: 100%;
  }
} */
@media (min-width: 601px) and (max-width: 1200px) {
  /* .second-title {
    display: none;
  }
  .fourth,
  .fifth,
  .sixth {
    width: 200px;
    height: 200px;
  } */
  .long {
    width: 420px;
    margin-right: 50px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    order: 2;
  }
  /* .second {
    width: 420px;
    margin-right: 50px;
  } */
  .transaction-date,
  .transaction-time,
  .history {
    display: none;
  }
  .left-side_sub {
    flex-direction: column;
  }
  .left-side_sub,
  .left-side {
    grid-gap: 30px;
  }
  .transaction-header button {
    flex-basis: 30% !important;
  }
}
@media (max-width: 600px) {
  /* .main-content{
    margin-bottom: 6.25rem;
  } */
  .left-side_sub {
    flex-direction: column;
  }
  .transaction-footer {
    padding: 1rem 0rem 0rem 1rem !important;
  }
  .left-side {
    grid-gap: 0px !important;
  }
  /* .hide-on-mobile {
    display: none !important;
  } */
  /* .inner {
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: row !important;
    order: 1 !important;
    gap: 10px !important;
  } */
  .tester {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
  }
  .fourth,
  .fifth,
  .sixth {
    width: 100px;
    height: 88px;
    padding: 0;
  }

  .long {
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
  }

  .transaction-date,
  .transaction-time,
  .history {
    display: none;
  }
  .custom-card-body {
    padding: 0.5rem 0 !important;
  }
  .mobile_check {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    flex-direction: column;
  }

  .transaction-body {
    padding: 1.2rem 0.8rem;
  }
  .transaction-header {
    margin: 0.5rem !important;
    padding-bottom: 1rem !important;
  }
  .balance {
    /* width: 340px !important; */
    height: 200px !important;
  }
  .transaction-header button {
    flex-basis: 30% !important;
  }
  p.wallet-amount {
    font-size: 1.25rem;
    margin: 0 !important;
  }
  .balance h4,
  p.wallet-balance {
    margin: 1.2rem 0 0 1.5rem !important;
  }

  /* .countryflag {
    width: 20%;
    bottom: 15px !important;
  } */
  .transaction-amount {
    margin-left: 0px !important;
  }
  .transaction_left_side {
    margin-left: 10px !important;
  }
  .title {
    font-family: Airbnb Cereal App, Bold;
    font-size: 0.725rem !important;
    margin-top: 5px !important;
    white-space: nowrap;
  }
  .second-title {
    display: block;
  }
  .first-title {
    display: none;
  }
  .wallet-balance {
    height: 400px !important;
  }
  .capsule {
    height: 90px !important;
  }
  .card-body {
    padding: 0 !important;
  }
}
@media (max-width: 320px) {
  .balance {
    width: 290px !important;
    height: 200px !important;
  }
  .long {
    width: 290px;
  }
  /* .fourth,
  .fifth,
  .sixth {
    width: 90px;
    height: 88px;
  } */
}
@media (max-width: 280px) {
  .balance {
    width: 260px !important;
    height: 200px !important;
  }
  .long {
    width: 260px;
  }
  /* .fourth,
  .fifth,
  .sixth {
    width: 80px;
    height: 88px;
  } */
  .mobile_check {
    margin-left: -20px;
  }
}
@media only screen and (min-width: 1100px) {
  /* .second-title {
    display: none;
  } */
}

.help__email__section {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 200px;
  padding: 30px 50px;
}
.help__email__section__text h4 {
  font-size: 1.25rem;
  font-family: "Airbnb Cereal App Bold";
  color: #1b1c1d;
  margin-bottom: 15px;
}
.help__email__section__text p {
  font-size: 1rem;
  font-family: "Airbnb Cereal App Book";
  color: #1b1c1d;
  margin-bottom: 15px !important;
}
.help__email__section__text a,
.help__email__section__text a.email:active,
.help__email__section__text a.email:hover,
.help__email__section__text a.email:visited {
  color: #1b1c1d !important;
  font-family: "Airbnb Cereal App Bold";
  text-decoration: none !important;
  cursor: pointer;
}
.help__email__section__button__section {
  display: flex;
  justify-content: flex-end;
}
.help__email__section__button {
  color: var(--main-white);
  text-align: center;
  font-size: 1rem;
  padding: 12px 40px;
  font-family: "Airbnb Cereal App Book";
  background-color: var(--button-color);
  border-radius: 10px;
  margin-top: 25px;
}
.help__email__main{
    width:80%;
}
@media only screen and (max-width: 600px) {
    .help__email__section {
        gap: 100px;
        padding: 35px 15px;
      }
}

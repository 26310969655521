.home-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 88%;
  margin: 0 auto;
  min-height: 8vh;
  padding: 1.5rem 0.5rem;
}
/* .second-header-text{
  display:flex;
  justify-content: flex-start;
  order:2;
} */
.second-header-text {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  line-height: 1.875rem;
  margin-bottom: -15px !important;
  text-transform: capitalize;
  /* width: 50% !important; */
  white-space: nowrap;
  order: 2;
}

.home-header > div {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 2rem;
}

.header-img-wrapper {
  display: flex;
  justify-content: space-between;
}

.header-img-wrapper div:first-child {
  order: 2;
  margin-right: 0.5rem;
  position: relative;
}

.header-img-wrapper div:last-child {
  display: flex;
  align-items: center;
}

.avatar {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
  border-radius: 50%;
}
.notification:hover,
.profile:hover{
cursor: pointer;
}
.notification p {
  position: absolute;
  top: -0.3rem;
  right: -0.5rem;
  width: 1.375rem;
  height: 1.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(27, 28, 29);
  color: #fff;
  font-size: 0.5rem;
  line-break: 0.625rem;
  border-radius: 50%;
}

.home-header h1 {
  margin-bottom: -100px;
  font-size: 1.75rem;
  font-family: "Airbnb Cereal App Bold";
  /* line-height: 1.875rem; */
  font-weight: bold;
  order: 2;
}

.main-header-btn {
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  z-index: 15;
}

.main-header-btn svg .a {
  fill: none;
  stroke-linecap: round;
  stroke-width: 2px;
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
}
.lottie-div {
  margin-bottom: -10px !important;
}
.headache{
  margin-bottom:-25px;
}
@media only screen and (min-width: 1400px) {
  .second-header-text {
    width: 5% !important;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1400px) {
  .second-header-text {
    width: 10% !important;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 992px) {
  .second-header-text {
    order: 1;
    margin-bottom: -50px!important;
  }
  .home-header {
    flex-direction: row;
    align-items: center;
    padding: 1rem 0.5rem;
  }

  .home-header h1 {
    order: 1;
  }

  .home-header > div {
    width: auto;
    order: 2;
    margin-bottom: 0;
  }

  .header-img-wrapper div:first-child {
    order: 1;
    margin-right: 0;
  }

  .header-img-wrapper div:last-child {
    margin-left: 1rem;
    order: 2;
  }

  .avatar {
    width: 46px;
    height: 46px;
  }

  .main-header-btn {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .second-header-text {
    width: 30% !important;
    white-space: nowrap;
  }
  .home-header h1 {

    font-size: 1rem;
  
  }
}

.wallet__history__section__main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 100px auto;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #efefef;
  width: 70%;
}
.wallet__history__section__main__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 40px 60px;
  /* height: auto; */
}
.wallet__history__section__main__header__first__section {
  display: flex;
  gap: 30px;
}
.wallet__history__section__main__header__first__section__image {
  height: 50px;
  width: 50px;
}
.wallet__history__section__main__header__first__section__image img {
  height: 100%;
  width: 100%;
}

.wallet__history__section__main__header__first__section__text p:first-child,
.wallet__history__section__main__header__second__section p:first-child {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  font-size: 1.25rem;
}

.wallet__history__section__main__header__first__section__text p:last-child,
.wallet__history__section__main__header__second__section p:last-child {
  color: #a8a8a8;
  font-family: "Airbnb Cereal App Book";
  font-size: 1.125rem;
}

.wallet__history__section__main__second__section {
  padding: 0px 60px 40px;
}
.wallet__history__info {
  padding: 1rem 0;
  margin: 1rem 0 1rem;
}
.wallet__history__info h5,
.wallet-history-feedback h5 {
  font-size: 1.25rem;
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
}

.wallet__history__info p {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 600;
}

.wallet__history__info p > span:first-child {
  color: #a8a8a8;
  font-size: 1.125rem;
  font-family: "Airbnb Cereal App Book";
}

.wallet__history__info p > span:last-child {
  color: #1b1c1d;
  font-size: 1.125rem;
  font-family: "Airbnb Cereal App Medium";
}

.wallet__history__info p:last-child > span:last-child {
  font-size: 1.375rem;
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Medium";
}
.wallet-history-feedback-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  margin-top: 10px;
  /* background-color: #f9fff9; */
  border: 1px solid #efefef;
  border-radius: 5px;
}

.wallet-history-feedback-msg p {
  text-transform: capitalize;
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: bold;
}

@media (max-width: 992px) {
  .wallet__history__section__main {
    margin: 50px auto 30px;
    width: 85%;
  }
  .wallet__history__section__main__header {
    padding: 30px 20px 20px;
    height: auto;
  }
  .wallet__history__section__main__header__first__section {
    gap: 10px;
  }
  .wallet__history__section__main__header__first__section__text p:first-child,
  .wallet__history__section__main__header__first__section__text p:last-child,
  .wallet__history__section__main__header__second__section p:first-child,
  .wallet__history__section__main__header__second__section p:last-child,
  .wallet__history__info h5,
  .wallet-history-feedback h5,
  .wallet__history__info p > span:first-child,
  .wallet__history__info p > span:last-child {
    font-size: 1rem;
  }

  .wallet__history__section__main__second__section {
    padding: 30px 20px;
  }
  .wallet__history__section__main__header__first__section__image {
    height: 40px;
    width: 40px;
  }

  .wallet__history__info p:last-child > span:last-child {
    font-size: 1.125rem;
  }
  .wallet-history-feedback-msg {
    padding: 30px 0;
    margin-top: 15px;
  }
}

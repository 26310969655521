.leaderboard-button .btn {
    padding: 0.7rem 1rem !important;
    white-space: nowrap;
    margin: 0px;
    border:none;
  }
  .leaderboard-button {
    border: 1px solid #efefef;
    padding: 10px 20px;
    border-radius: 5px;
    margin-left: 20px;
    align-items: center;
  }
.leaderboard__main {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 380px 1fr;
  margin: 70px auto 30px;
  border: 1px solid #efefef;
}
.leaderboard__section__trader {
  font-size: 0.875rem;
  color: #459f45;
  font-family: "Airbnb Cereal App Bold";
  padding: 20px;
  text-align: right;
}
.leaderboard__list__section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border-bottom: 1px solid #EFEFEF; */
  padding: 20px 0 10px;
}
.leaderboard__list__section:nth-child(1),
.leaderboard__list__section:nth-child(2) {
  border-bottom: 1px solid #efefef;
}
.leaderboardmobile__list:nth-child(2) {
  margin: 0 20px;
}
#root
  > div
  > div
  > main
  > main
  > div.main-content
  > div
  > div:nth-child(2)
  > div
  > div.content-avatar-tabs
  > div.avatar-content.active-avatar-content
  > section
  > div
  > div
  > ul
  > div:nth-child(2)
  > div.leaderboardmobile__list__section__image {
  width: 122px;
  height: 122px;
  margin-top: -50px;
}
.leaderboardmobile__list__section__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  position:relative
}
#root > div > div > main > main > div.main-content > div > div:nth-child(2) > div > div.content-avatar-tabs > div.avatar-content.active-avatar-content > section > div > div > ul > div:nth-child(1) > div.leaderboardmobile__list__section__image > p,
#root > div > div > main > main > div.main-content > div > div.redem__avatar__avatar__section > div > ul > div:nth-child(2) > div.leaderboard__list__section__image > p{
  background-color: #fe9dfa;
}
#root > div > div > main > main > div.main-content > div > div:nth-child(2) > div > div.content-avatar-tabs > div.avatar-content.active-avatar-content > section > div > div > ul > div:nth-child(3) > div.leaderboardmobile__list__section__image > p,
#root > div > div > main > main > div.main-content > div > div.redem__avatar__avatar__section > div > ul > div:nth-child(3) > div.leaderboard__list__section__image > p{
  background-color: #6C63FF;
}
.leaderboard__span{
  position:absolute;
  top:-15px;
  background-color: #F1C410;
  color:#efefef;
  width:25px;
  height:25px;
  border-radius: 50%;
  text-align: center;
}
.leaderboard__crown__span{
  position:absolute;
  top:-50px;
}
.leaderboard__list__section__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  position: relative;
}

.leaderboard__list__section__text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 10px 0;
}
.leaderboard__list__section__first__text {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Medium";
}
.leaderboardmobile__list__first__text {
  color: #1b1c1d;
  font-size: 0.75rem;
  font-family: "Airbnb Cereal App Medium";
}
.leaderboardmobile__list__second__text {
  color: #6f6f6f;
  font-size: 0.75rem;
  font-family: "Airbnb Cereal App Book";
}
.leaderboard__list__section__second__text {
  color: #6f6f6f;
  font-size: 0.75rem;
  font-family: "Airbnb Cereal App Book";
}
.leaderboard__list__section__section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.leaderboard__list__section__section img{
  margin-left: 5px;
}
.leaderboard__list__section__section__image {
  display: flex;
  margin-left: 5px;
  width: 11px;
  height: 11px;
}
#root > div > div > main > main > div.main-content > div > div:nth-child(2) > div > div.content-avatar-tabs > div.avatar-content.active-avatar-content > section > div > div > ul > div:nth-child(2) > div.leaderboardmobile__list__section__image > img,
#root > div > div > main > main > div.main-content > div > div.redem__avatar__avatar__section > div > ul > div:nth-child(1) > div.leaderboard__list__section__image > img{
  border: 2px solid #F1C410;

}
#root > div > div > main > main > div.main-content > div > div:nth-child(2) > div > div.content-avatar-tabs > div.avatar-content.active-avatar-content > section > div > div > ul > div:nth-child(3) > div.leaderboardmobile__list__section__image > img,
#root > div > div > main > main > div.main-content > div > div.redem__avatar__avatar__section > div > ul > div:nth-child(3) > div.leaderboard__list__section__image > img{
  border: 2px solid #6C63FF;
 
}
.leaderboard__list__section__img {
  border: 2px solid #fe9dfa;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.leaderboard__list__section {
  direction: ltr;
}
.giftcard__traders {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.leaderboardmobile__ul {
  display: flex;
  margin: 20px 30px;
}
.leaderboardmobile__list:nth-child(n) {
  border: none;
}
.leaderboard__giftcard__list__section__details__icon{
  margin-top: -20px;
  width: 20px;
  height: 10px;
}
.leaderboard__giftcard__list__section__details__icon img{
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1400px) {
  .leaderboard__main {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .leaderboardmobile__list:nth-child(2) {
    margin: 0 10px;
  }
  #root
    > div
    > div
    > main
    > main
    > div.main-content
    > div
    > div:nth-child(2)
    > div
    > div.content-avatar-tabs
    > div.avatar-content.active-avatar-content {
    padding: 70px 0px 0px;
  }
  .leaderboardmobile__list__first__text {
    font-size: 0.625rem;
  }
  .leaderboard__giftcard__list__section__details__icon{
    width: 10px;
    height: 5px;
  }
  .leaderboardmobile__ul {
    margin: 20px 0px;
  }
}

.form-progress > div {
  display: grid;
  width: 100%;
  margin: 0 auto;
}

.form-progress > div:first-child {
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 0.5rem;
}

.form-progress > div:last-child {
  grid-template-columns: auto 1fr auto;
}

.form-progress span {
  display: inline-block;
  font-size: 0.5rem;
  line-height: 1.0625rem;
  font-family: "Airbnb Cereal App Bold";
}

.form-progress p {
  font-size: 0.625rem;
  line-height: 1.875rem;
  font-family: "Airbnb Cereal App Bold";
  /* font-weight: bold; */
}

.form-progress .center-text {
  text-align: center;
  _justify-self: center;
}

.form-progress p:last-child {
  text-align: right;
}

.form-progress .step {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #1B1C1D;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.form-progress .center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-progress .center::before,
.form-progress .center::after {
  content: "";
  flex: 1;
  background-color: #1B1C1D;
  height: 0.5px;
}

@media only screen and (min-width: 600px) {
  .form-progress .step {
    width: 44px;
    height: 44px;
  }

  .form-progress p {
    font-size: 0.875rem;
  }
}

@media only screen and (min-width: 768px) {
  .form-progress {
    width: 82%;
  }

  .form-progress .progress-step {
    padding: 0 3rem 0 2rem;
  }

  .form-progress span {
    font-size: 0.75rem;
    line-height: 1.75rem;
    font-family: "Airbnb Cereal App Bold";
  }
}

@media only screen and (min-width: 992px) {
  .form-progress {
    width: 95%;
  }

  .form-progress p {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1220px) {
  .form-progress {
    width: 82%;
  }
}
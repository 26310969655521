.main-content .giftcard-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2.5rem;
  padding: 1rem 0;
}

.progress-header {
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 2rem;
  min-height: 10vh;
  padding: 0 0 0.5rem;
}

.progress-btn {
  align-self: flex-start;
  width: 52px;
  height: 52px;
  background-color: #fdfdfd;
  border: 1px solid #1b1b1b;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* display: none; */
}

.giftcard-details form {
  padding: 0.5rem;
  margin-bottom: 6.25rem;
}


.giftcard-details form > div {
  margin-bottom: 1rem;
}
.giftcard_form {
  width: 100%;
}

.giftcard-details form > section {
  text-align: right;
  padding-top: 1rem;
}

.giftcard-details .countries {
  padding-bottom: 1rem;
}

.giftcard-details .form-group label {
  margin-bottom: 0.5rem;
}

.giftcard-details .card-details {
  background-color: #f9f9f9;
  border-radius: 3px;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
}

.giftcard-details .card-details h4 {
  display: none;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #1b1b1b;
  font-weight: bold;
  margin-bottom: 4rem;
}

.giftcard-details label {
  font-size: 1rem;
  line-height: 1.874rem;
  font-family: "Airbnb Cereal App Bold";
  /* font-weight: bold; */
  white-space: nowrap;
}

.card-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  align-items: center;
}

.giftcard-details .amount-input {
  width: 100%;
  display: block;
  padding: 0.625rem 1.25rem 0.625rem 0.8rem;
  border-radius: 4px;
  border: 1px solid rgb(112, 112, 112);
  background-color: transparent;
  color: #1b1b1b;
  font-family: "Airbnb Cereal App Medium";
}
/* .giftcard-details .amount-input:disabled {
  color: #1b1b1b!important;
  opacity: 1!important;
  font-family: "Airbnb Cereal App Medium";
} */
input[type="number"]:disabled {
  color: #1b1b1b !important;
  opacity: 1 !important;
}

.giftcard-details .rate {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.giftcard-details .rate-note {
  font-size: 0.6875rem;
  line-height: 1.5rem;
  color: #a8a8a8;
  font-weight: 600;
  font-family: "Airbnb Cereal App Book";
}

.giftcard-details .rate-value {
  font-size: 0.8125rem;
  line-height: 1.5rem;
  color: #459f45;
  font-family: "Airbnb Cereal App Bold";
  /* font-weight: bold; */
}

.giftcard-btn {
  display: inline-block;
  color: #fdfdfd;
  font-size: 0.75rem;
  line-height: 1.0625rem;
  font-family: "Airbnb Cereal App Bold";
  /* font-weight: bold; */
  text-transform: capitalize;
  border: 1px solid #1b1c1d;
  border-radius: 10px;
  background-color: #1b1c1d;
  padding: 1rem 2rem;
  outline: none;
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

@media only screen and (min-width: 600px) {
  .giftcard-details .card-details {
    justify-content: flex-start;
    height: 90%;
  }

  .progress-header {
    grid-gap: 4rem;
  }

  .giftcard-details .card-details h4 {
    display: block;
  }

  .progress-header {
    grid-template-columns: auto 1fr;
    align-items: flex-end;
    min-height: 20vh;
    padding-top: 1.5rem;
  }

  .giftcard-details .rate-note {
    font-size: 0.875rem;
  }

  .giftcard-details .rate-value {
    font-size: 1rem;
  }
 

  /* .progress-btn {
    display: block;
  } */
}

@media only screen and (min-width: 768px) {
  .giftcard-details .card-details h4 {
    font-size: 1.75rem;
    line-height: 2.125rem;
    font-family: "Airbnb Cereal App Bold";
  }

  .giftcard-details label {
    font-size: 1.25rem;
  }

  .giftcard-btn {
    font-size: 0.875rem;
    line-height: 1.375rem;
    padding: 1rem 3rem;
  }
  .card-details-img-container {
    width: 345px;
    height: 206px;
  }
  .card-details-img-container img {
    width: 100%;
    height: 100%;
  }
}

@media only screen and(min-width: 992px) {
  .progress-header {
    grid-gap: 4.5rem;
  }
  
.giftcard-details form {
  margin-bottom: 0;
}

  
}

@media only screen and (min-width: 1200px) {
  .progress-header {
    max-width: 88%;
    margin: 0 auto;
    grid-gap: 6.7rem;
  }
}

.settings__section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  background-color: #fff;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 10px;
  width: 674px;
  flex-direction: column;
  margin: 100px auto 50px;
  padding: 70px 54px;
  /* height: 741px; */
  /* margin: 40px; */
}
.settings__section__content {
  width: 100%;
}
.settings__section__heading {
  color: #1b1c1d;
  font-size: 1.875rem;
  font-family: "Airbnb Cereal App Medium";
}
.settings__section__settings-label {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  font-size: 1rem;
}
.settings__section__form-group {
  width: 100%;
  margin: 15px auto 5px;
}
.settings__section__input,
.delete__account__textarea {
  width: 100%;
  display: block;
  padding: 0.625rem 1.25rem 0.625rem 0.8rem;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  color: #1b1b1b;
  font-family: "Airbnb Cereal App Medium";
}
.settings__section__input {
  height: 60px;
}
.delete__account__textarea {
  resize: none;
  /* overflow: hidden; */
  min-height: 120px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.delete__account__textarea::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.delete__account__textarea {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.delete__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.delete__section__text {
  margin-top: 10px;
}
.delete__section__first__text {
  font-size: 0.875rem;
  color: #1b1b1b;
  font-family: "Airbnb Cereal App Bold";
}
.delete__section__second__text {
  font-size: 0.875rem;
  color: #6f6f6f;
  font-family: "Airbnb Cereal App Bold";
}
/* .delete__section__image {
  width: 58px;
  height: 58px;
}
.delete__section__image img {
  width: 100%;
  height: 100%;
} */

.settings__delete__section {
  margin-top: 50px;
}
.settings__delete__section__heading {
  color: #1b1c1d;
  font-size: 1.375rem;
  font-family: "Airbnb Cereal App Medium";
}
.settings__delete__button__section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.settings__delete__section__button {
  color: white;
  text-align: center;
  font-size: 1rem;
  padding: 20px 30px;
  font-family: "Airbnb Cereal App Book";
  background-color: #1b1c1d;
  border-radius: 10px;
  margin-top: 35px;
  width: 90%;
  
}
.settings__delete__section__button:disabled {
  background-color: #fff;
  color: #ccc;
  border: 1px solid #d3d3d3;
  cursor: no-drop;
}
@media (max-width: 991px) {
  .settings__section{
    margin-bottom: 6.25rem;
  }
}

@media screen and (max-width: 767px) {
  .settings__section {
    padding: 35px 5px;
    width: 440px;
    /* margin: 70px auto 20px; */
  }
  .settings__section__content {
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  .settings__section {
    padding: 35px 5px;
    width: 370px;
  }
  .settings__section__heading{
    font-size: 1.35rem;
  }
  .settings__section__settings-label{
    font-size: 0.875rem;
  }
  .delete__section__second__text {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 400px) {
  .settings__section {
    padding: 35px 15px;
    width: 320px;
  }
  .settings__section__content {
    width: 100%;
    margin: auto;
  }
  .delete__account__toggle {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
  }
}
@media screen and (max-width: 280px) {
  .settings__section  {
    width: 250px;
  }
}

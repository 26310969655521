.privacy__section {
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 10px;
  width: 674px;
  margin: 100px auto 50px;
}
.privacy__section__header__image {
  width: 187px;
  height: 98px;
}
.privacy__section__header__image img {
  width: 100%;
  height: 100%;
}

.privacy__section__header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: #1b1c1d;
  color: #fff;
  padding: 60px 54px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.privacy__section__header h3 {
  font-size: 1.35rem;
  margin: 10px 0px 15px;
  font-family: "Airbnb Cereal App Extra Bold";
}
.privacy__section__header p {
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Book";
}
.privacy__section__listings {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  padding: 60px 54px;
}
.privacy__section__listings__heading {
  color: #6c63ff;
  margin: 20px auto;
  font-size: 1.5rem;
  font-family: "Airbnb Cereal App Extra Bold";
}
.privacy__section__listings__text {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Bold";
  line-height: 2;
  /* margin-bottom: 2.5rem; */
}
@media (max-width: 991px) {
  .privacy__section{
    margin-bottom: 6.25rem;
  }
}

@media screen and (max-width: 767px) {
  .privacy__section {
    /* padding: 35px 5px; */
    width: 440px;
    /* margin: 70px auto 20px; */
  }
  .privacy__section__content {
    width: 90%;
    margin: auto;
  }
  .privacy__section__listings__heading {
    margin: 20px 0px;
  }
}
@media screen and (max-width: 600px) {
  .privacy__section__listings,
  .privacy__section__header {
    padding: 60px 24px;
  }
  .privacy__section__listings__text {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 500px) {
  .privacy__section {
    width: 370px;
  }
}

@media screen and (max-width: 400px) {
  .privacy__section {
    width: 320px;
  }
  .privacy__section__content {
    width: 100%;
    margin: auto;
  }
}
@media screen and (max-width: 280px) {
  .privacy__section {
    width: 250px;
  }
  .privacy__section__content {
    width: 100%;
    margin: auto;
  }
}

.slide-container {
  overflow: hidden;
}

.slider-wrapper {
  overflow: hidden;
  height: 8rem;
  position: relative;
}

.slider-wrapper::-webkit-scrollbar {
  appearance: none;
  display: none;
}

.slider {
  display: grid;
  grid-template-columns: 16.5% 16.5% 1fr 16.5% 16.5%;
  grid-gap: 0.5rem;
  align-items: center;
  position: absolute;
  padding: 0.5rem 0;
  -webkit-transform: scaleX(1.4);
  -moz-transform: scaleX(1.4);
  transform: scaleX(1.4);
}

.slide-item {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  -webkit-transition: width 0.4s ease-out;
  -moz-transition: width 0.4s ease-out;
  transition: width 0.4s ease-out;
}

.slide-item > img {
  width: 100%;
  height: 60px;
  object-fit: fill;
  -webkit-transition: height 0.4s ease-out;
  -moz-transition: height 0.4s ease-out;
  transition: height 0.4s ease-out;
}

.slide-item > img.active {
  height: 80px;
}

.slider-controls {
  width: 80%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-container input:disabled {
  text-transform: capitalize;
  padding: 0.625rem 0.8rem;
  border-radius: 5px;
  border: 1px solid #EFEFEF;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 700;
  width: 75%;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.slide-container button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.slide-container button:focus,
.slide-container button:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.161); 
}

.next {
  margin-left: 1.5rem;
}

.prev {
  margin-right: 1.5rem;
}

@media only screen and (min-width: 480px) {
  .slider {
    -webkit-transform: scaleX(1.2);
    -moz-transform: scaleX(1.2);
    transform: scaleX(1.2);
    grid-template-columns: 17% 17% 1fr 17% 17%;
  }
}

@media only screen and (min-width: 768px) {
  .slider {
    grid-gap: 0.625rem;
  }

  .slide-item > img {
    height: 100px;
  }

  .slide-item img.active {
    height: 120px;
  }

  .slide-container button {
    height: 30px;
    width: 30px;
  }
}

@media only screen and (min-width: 1110px) {
  .slide-item > img {
    height: 60px;
  }
  .slide-item img.active {
    height: 90px;
  }
}
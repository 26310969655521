/* .headache {
  display: none;
} */

.redem-avatar-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 88%;
  margin: 0 auto;
  margin-bottom: -20px;
  padding: 0 0.5rem;
}

.redem-avatar-header-rank {
  /* margin-top: 20px; */
  font-size: 1.75rem;
  line-height: 1.875rem;
  font-weight: bold;
}

.redem-avatar-button .btn {
  padding: 0.7rem 1rem !important;
  white-space: nowrap;
  margin-left: 20px;
}
.redem-avatar-button {
  border: 1px solid #efefef;
  padding: 10px 20px;
  border-radius: 5px;
  margin-left: 20px;
  align-items: center;
}
.redem-avatar-button a {
  color: #1b1c1d !important;
  text-decoration: none !important;
}
.redem__avatar__text {
  color: #1b1c1d;
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Book";
  margin-bottom: 0 !important;
}
.current__level {
  color: #b3b5bb;
  font-size: 0.75rem;
  font-family: "Airbnb Cereal App Medium";
}
.current__level__text {
  color: #1b1c1d;
  font-size: 0.75rem;
  font-family: "Airbnb Cereal App Medium";
}

@media (max-width: 992px) {
  .redem-avatar-button .btn {
    display: none;
  }
  .redem-avatar-button {
    border: none;
  }
  .redem-avatar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
  }
}

@media (max-width:600px){
.redem-avatar-header-rank{
  font-size: 1rem;
}
}

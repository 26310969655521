.cover__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}
.cover__list__section__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 229px;
  height: 93px;
  margin: 20px 10px;
}
.cover__list__section__img {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .cover__list__section__image {
    width: 120px;
    height: 50px;
    margin: 5px;
  }
}
@media only screen and (max-width: 500px) {
  .cover__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  .cover__list__section__image {
    margin: 0px;
  }

}
@media only screen and (max-width: 300px) {
    .cover__list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 20px;
    }
  }

.leader-board {
  _flex: 3;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.leader-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.875rem;
  margin-bottom: 0.5rem;
}

.leader-header h3 {
  font-size: 1.25rem;
  font-weight: bold;
  /* flex-basis: 50%; */
  font-family: "Airbnb Cereal App Bold";
}

.leader-header button {
  padding: 1rem;
  font-size: 0.875rem;
  background-color: #f8f8f8;
  border-radius: 10px;
  border: none;
  flex-basis: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Airbnb Cereal App Medium";
}

.leader-item {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-column-gap: 1rem;
  padding: 0.5rem 0;
}

.leader-avatar-wrapper {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-top: -10px;
}
.leader-avatar-wrapper img{
  width: 100%;
  height: 100%;
}

.leader-name {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: bold;
  color: rgb(27, 28, 29);
  font-family: "Airbnb Cereal App Bold";
}
.leader-name-con {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
/* p.leader-name-con p{

} */

.leader-name-con p{
  font-size: 0.75rem;
  color: rgb(168, 168, 168);
  font-weight: normal;
  font-family: "Airbnb Cereal App Book";
}

.leader-icon-wrapper {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
}
.leader-icon-wrapper p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  font-size: 1rem;
  border: 1px solid #dadada;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.leader-footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.2rem 0;
}

.leader-footer p {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: medium;
  text-decoration: underline;
  color: rgb(27, 28, 29);
}
@media (max-width: 601px) {
  .leader-board {
    margin-top: 1rem;
    /* margin-bottom: 6.25rem; */
  }
}
@media (max-width: 991px) {
  .leader-board {
    margin-top: 2rem;
   
  }
}

@media only screen and (min-width: 600px) {
  .leader-board button {
    padding: 0.5rem;
  }
}

@media only screen and (min-width: 992px) {
  .leader-board {
    margin-top: 0;
  }
}

.wallet__main__component {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.wallet__transfer__container {
  width: 90%;
}

.transfer__withdraw__component__main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-radius: 10px;
  background-color: #fafaff;
}
.transfer__withdraw__component__main a,
.transfer__withdraw__component__main a:visited,
.transfer__withdraw__component__main a:active,
.transfer__withdraw__component__main a:hover {
  color: #1b1c1d;
  text-decoration: none;
  font-family: "Airbnb Cereal App Bold";
}
.withdraw {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff7f8;
  border: 0.5px solid #ec1a3f;
  border-radius: 15px;
  padding: 20px 40px;
  margin: 0 20px;
  width: 195px;
  height: 132px;
}
.transfer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #efeeff;
  border: 0.5px solid #6c63ff;
  border-radius: 15px;
  padding: 20px 40px;
  margin: 0 20px;
  width: 195px;
  height: 132px;
}
.withdraw__image__main,
.transfer__image__main {
  width: 100%;
  height: 100%;
}
.withdraw__image {
  width: 80px;
  height: 80px;
}
.transfer__image {
  width: 80px;
  height: 80px;
}

@media only screen and (max-width: 992px) {
  .wallet__main__component {
    margin-top: 50px;
    flex-direction: column;
  }
  .transfer__withdraw__component__main {
    margin: 40px 0 30px;
  }
  .wallet__transfer__container {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .withdraw,
  .transfer {
    width: 150px;
  }
}

@media only screen and (max-width: 400px) {
  .withdraw,
  .transfer {
    margin: 0 10px;
    padding: 15px 40px;
  }
  .transfer__image {
    width: 70px;
    height: 60px;
  }
  .withdraw__image {
    width: 60px;
    height: 60px;
  }
}

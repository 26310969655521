.transaction-content {
  padding-bottom: 0;
}

.history-header > button {
  padding: 0.625rem;
  font-size: 0.875rem;
  background-color: #F8F8F8;
  border-radius: 10px;
  border: none;
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.3 ease-out;
  -moz-transition: all 0.3 ease-out;
  transition: all 0.3 ease-out;
}

.history-list {
  border-right: none;
  padding-bottom: 1.2rem;
  /* height:700px; */
  overflow-x: hidden;
  /* overflow-y: scroll; */
  /* direction: ltr; */
}

.history-item {
  border-bottom: 0.5px solid #EFEFEF;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem 0.5rem;
  cursor: pointer;
  margin:10px 10px 10px 0;
}

.history-item .active {
  background-color: #F8F8F8;
}

.history-details p {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-family: "Airbnb Cereal App Bold";
    text-transform: capitalize;
    color: #1B1C1D;
}

.history-details p:last-child {
  font-size: 0.75rem;
  color: #A8A8A8;
  font-family: "Airbnb Cereal App Book";
}

.history-amount {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-family: "Airbnb Cereal App Bold";
  /* color: #1B1C1D; */
  text-align: right;
}
.giftcard__mobile{
  padding-top: 10px;
}
/* Single Transaction Detail */

.transaction-details {
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  height:700px;
  overflow-x: hidden;
  overflow-y: scroll;
  /* overflow: hidden; */
}

.item-cta {
  color: #A8A8A8;
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
  font-family: "Airbnb Cereal App Book";
}

.single-transaction-detail {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0.8rem;
  background-color: #ffffff;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: transform 0.5s ease-out;
  -moz-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  visibility: hidden;
  opacity: 0;
}

.show-detail {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

.single-transaction-detail h5 {
  font-size: 1rem;
  line-height: 1.875rem;
  font-weight: bold;
  text-transform: capitalize;
  color: #1B1C1D;
  font-family: "Airbnb Cereal App Bold";
}

/* Single item Transaction period */

.transaction-period {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 1rem;
  padding: 0.5rem 0 1.25rem;
  border-bottom: 0.5px solid #EFEFEF;
  cursor: pointer;
}

.transaction-period .details-title p {
  line-height: 1.375rem;
  font-weight: bold;
  text-transform: capitalize;
}

.transaction-period .details-title p:first-child {
  font-size: 0.875rem;
  color: #1B1C1D;
  font-family: "Airbnb Cereal App Bold";
}

.transaction-period .details-title p:last-child {
  font-size: 0.75rem;
  color: #A8A8A8;
  font-family: "Airbnb Cereal App Book";
}

.transaction-period .details-time p {
  line-height: 1.375rem;
  font-weight: bold;
  text-transform: capitalize;
}

.transaction-period .details-time p:first-child {
  font-size: 0.875rem;
  color: #1B1C1D;
  font-family: "Airbnb Cereal App Bold";
}

.transaction-period .details-time p:last-child {
  font-size: 0.75rem;
  color: #A8A8A8;
  font-family: "Airbnb Cereal App Book";
}

/* Single item Transaction trade info */ 

.trade-info {
  padding: 1rem 0;
  margin-top: 2rem;
  color: #1B1C1D;
}

.trade-info p {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 600;
}

.trade-info p > span:first-child {
  color: #A8A8A8;
  text-transform: capitalize;
  font-family: "Airbnb Cereal App Book";
}

.trade-info p > span:last-child {
  color: #1B1C1D;
  font-family: "Airbnb Cereal App Bold";
}

.trade-info p:last-child > span:last-child {
  font-weight: bold;
  font-weight: 1rem;
}

/* Transaction Feedback */

.feedback-container {
  display: grid;
  grid-template-columns: 1fr 6.25rem;
  grid-gap: 1rem;
  padding:20px 0px 30px;
}

.feedback-info {
  background-color: #F8F8F8;
  padding: 0 0.5rem;
}

.feedback-info_content {
  width: 85%;
  margin: 0 auto;
  padding: 0.75rem 0.5rem;
  background-color: #ffffff;
}

.feedback-info_content .icon-wrapper {
  width: 100%;
  padding: 0.2rem;
}

.feedback .more-info p {
  margin-bottom: 0.2rem;
}

/* .feedback .more-info p:first-child,
.details-modal_content .more-info p:first-child {
  font-size: 0.5rem;
  line-height: 1.375rem;
  color: #1B1C1D;
  border-bottom: 0.2px solid #94ABCB;
  font-weight: bold;
  margin-bottom: 0;
} */

.feedback .more-info p:nth-child(2),
.feedback .more-info p:last-child,
.details-modal_content .more-info p:nth-child(2),
.details-modal_content .more-info p:last-child {
  font-size: 0.4375rem;
  line-height: 0.75rem;
  color: #1B1C1D;
}

.feedback .more-info p:nth-child(2) a:hover,
.details-modal_content .more-info p:nth-child a:hover {
  text-decoration: underline;
}

.feedback-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
}

.feedback-msg p {
  text-transform: capitalize;
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: bold;
}

/* Transaction Details modal */

.details-modal_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: opacity transform;
  -moz-transition-property: opacity transform;
  transition-property: opacity transform;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

.show-modal {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.details-modal {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 1rem;
  height: 100%;
}

.details-modal_header {
  text-align: right;
  padding: 0.5rem 0;
}

.details-modal_header button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  outline: none;
}

.details-modal_content {
  padding: 1rem;
  background-color: #ffffff;
  align-self: center;
  position: relative;
}

.details-modal_content .icon-wrapper {
  width: 100%;
  padding: 0.2rem;
}

.icon-wrapper img {
  width: 100%;
}

.details-modal_content .more-info p {
  margin-bottom: 1.5rem;
}

.details-modal_content .more-info p:first-child {
  font-size: 0.75rem;
  margin-bottom: 0.2rem;
}
.transaction__tooltip__container{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.transaction__tooltip__container a,
.transaction__tooltip__container a:active,
.transaction__tooltip__container a:visited,
.transaction__tooltip__container a:hover{
 color:#1B1C1D;
}

.transaction__tooltip button{
  border: none;
  background-color: transparent;
}
.transaction__tooltip p{
  font-size:0.75rem;
}
.transaction__tooltip__image{
  width:12px;
  height: 12px;
  margin-left:7px;
  margin-top:-12px;
}
.transaction__tooltip__image img{
  width:100%;
  height: 100%;
}
.transaction__tooltip{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 601px) {
  .history-header > button {
    padding: 0.5rem;
  }

  .history-list {
    border-right: 0.5px solid #EFEFEF;
  }
}

@media only screen and (min-width: 768px) {
  .transaction-details {
    display: flex;
  }
}

@media only screen and (min-width: 1200px) {

}

.forgot__password__validate__email__sub__heading {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Book";
  margin-top: 20px;
  margin-bottom: 30px !important;
  /* text-align: center; */
}
.forgot__password__validate__email__sub__heading span {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
}
.resend__code {
  margin-top: 80px;
}
.resend__code span {
  color: #1b1c1d;
  text-decoration: underline;
  font-family: "Airbnb Cereal App Bold";
  cursor: pointer;

}
.forgot__password__sub__heading__resend__code span{
  cursor: pointer;
}
.emailbox{
  width:76px;
  height: 76px;
}
.emailbox img{
  width:100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .forgot__password__validate__email__sub__heading {
    margin-bottom: 30px !important;
    margin-top: 20px;
  }
}
@media (min-width: 992px) {
  .emailbox{
   margin-bottom: 30px;
  }
}

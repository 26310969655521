* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
:root {
  --primary--color: #000;
  --secondary-color: #fff;
  --extra-color: #878788;
  --button-color: #1b1c1d;
  --bg-color: rgba(255, 255, 255, 0.5);
  --p-color: #868593;
  --off-white: #d6d6d6;
  --p-white: #838383;
  --off-color: #00000029;
  --other-color: rgba(0, 0, 0, 0.3);
  --nav-color: rgba(0, 0, 0, 0.2);
  --menu-color: rgb(252, 252, 252);
  --sub-color: #ccc;
  --hover-color: rgb(219, 219, 219);
  --wrapper-color: #efefef;
  --slider-color: rgb(134, 133, 147);
  --video-color: #f6f6f6;
  --header-color: rgb(189, 189, 202);
  --main-white: #ffffff;
  --main-grey: #6f6f6f;
  --border-color: #232323;
  --grey-color: #a8a8a8;
  --border-border: #d3d3d3;
}
/* Browser Default Reset */

h1,
h2,
h3,
h4,
h5,
h6 {
  overflow: hidden !important;
}

@font-face {
  font-family: "Airbnb Cereal App Book";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Book"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBook.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Light";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Light"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealLight.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Medium"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealMedium.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Bold"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBold.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Extra Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Extra Bold"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealExtraBold.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Black";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Black"),
    url("../../fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBlack.woff")
      format("woff");
}
.child {
  box-shadow: 0px 0px 3px var(--off-color);
  min-height: 90vh;
  flex-basis: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 30px 40px;
  padding: 20px 0px;
}

form {
  width: 80%;
}
.signup-right--section--h2 {
  text-align: center;
  font: normal normal bold 22px Airbnb Cereal App Bold;
  color: var(--secondary--color);
  opacity: 1;
  line-height: 40px;
  margin-top: 20px;
  /* margin-bottom: 1rem; */
}
.signup-right--section--p {
  text-align: center;
  font: normal normal normal 14px Airbnb Cereal App Book;
  color: var(--grey-color);
  opacity: 1;
  white-space: nowrap;
}
.signup-form-container label {
  display: none !important;
}
.signup-form-container {
  position: relative;
}

.signup-form-control:focus {
  outline: none !important;
  border: 1px solid var(--grey-color) !important;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 100% !important;
  outline: none;
}
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
  background-color: white;
  width: 285px !important;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}

.signup-form-control {
  display: block;
  width: 100%;
  outline: none !important;
  height: 62px !important;
  margin: 1rem 0 !important;
  padding: 0.8rem !important;
  color: var(--main-grey) !important;
  font-size: 16px !important;
  font-family: Airbnb Cereal App Medium;
  font-weight: medium;
  line-height: 1.5;
  background-color: var(--main-white);
  background-clip: padding-box;
  border: 1px solid var(--border-border);
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#root
  > div
  > div
  > div:nth-child(2)
  > main
  > section
  > div
  > div
  > div
  > form
  > div.signup-form-container.signup-input-form-container.d-flex
  > input,
#root
  > div
  > div
  > div:nth-child(2)
  > main
  > section
  > div
  > div
  > div
  > form
  > div.signup-form-container.signup-input-form-container.d-flex
  > select {
  margin: 0 !important;
}
.signup-form-control:hover {
  border: 1px solid var(--grey-color) !important;
}
.error {
  color: red;
}
.select-form-control {
  display: block;
  width: 30%;
  outline: none !important;
  height: 62px !important;
  margin: 1rem 0 !important;
  padding: 0.8rem !important;
  font-size: 16px !important;
  font-family: Airbnb Cereal App Medium;
  font-weight: medium;
  line-height: 1.5;
  background-color: var(--main-white);
  background-clip: padding-box;
  border: 1px solid var(--border-border);
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#password-field,
#phone {
  position: relative;
}
.field-con {
  right: 5px;
  top: 25px;
  position: absolute;
  z-index: 2;
  padding-right: 15px !important;
  color: black !important;
}
.arrow-down {
  display: none;
}
.response__status p {
  padding: 10px;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Medium";
}
.response__status {
  /* display: none; */
  margin-bottom: 10px;
  /* border-radius: 10px; */
}
.referral {
  position: relative;
}
.referral__button {
  right: 8px;
  top: 10px;
  position: absolute;
  z-index: 2;
  padding: 5px 10px;
  color: #fff;
  background-color: #1b1c1d;
  border-radius: 10px;
}

.form-check-label {
  font: normal normal normal 14px Airbnb Cereal App Book;
}
.form-check-label a,
.form-check-label a:visited,
.form-check-label a:hover,
.form-check-label a:active {
  color: red;
}
.form-check .form-check-input {
  margin-top: 4px;
}
.form-check-label {
  color: var(--secondary--color);
  font: normal normal normal 14px Airbnb Cereal App Book;
}
.form-check .form-check-input {
  margin-top: 6px;
}
.form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}
.sign--up--button {
  border: 2px solid var(--primary--color);
  border-radius: 10px;
  margin-top: 10px;
  height: 50px;
  /* width: 35%; */
  width: 100%;
  background-color: var(--primary--color);
  color: var(--secondary-color);

  text-align: center;
  font: normal normal bold 14px/22px Airbnb Cereal App Bold;

}
.signup__loading__button{
  border: 2px solid var(--primary--color);
  border-radius: 10px;
  margin-top: 10px;
  height: 50px;
  /* width: 35%; */
  width: 100%;
  background-color: transparent;
  text-align: center;
}
.sign--up--button:hover {
  color: var(--secondary--color);
  background-color: var(--main-white);
}
.bottom {
  margin-top: 10px;
}
.bottom-link,
.bottom-link:visited,
.bottom-link:hover,
.bottom-link:active {
  font: normal normal normal 14px Airbnb Cereal App Book;
  color: red;
}
.already {
  font: normal normal normal 14px Airbnb Cereal App Book;
  color: var(--button-color);
}

@media (min-width: 1200px) and (max-width: 1477px) {
  .form-check-label {
    margin-top: -30px;
  }
}
@media (min-width: 1200px) {
  .child {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    padding: 0px 100px;
  }
}
@media (min-width: 1024px) {
  .signup-right--section--p {
    white-space: normal;
  }
}
@media (min-width: 600px) {
  .arrow-down {
    display: block;
    width: 13px;
    height: 13px;
    left: 90px;
    top: 18px;
    position: absolute;
    z-index: 2;
  }
  .arrow-down img {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .signup-right--section--h2 {
    font: normal normal bold 32px Airbnb Cereal App Bold;
  }
  .signup-right--section--p {
    font: normal normal normal 22px Airbnb Cereal App Book;
  }

  .signup-form-control {
    font-size: 22px !important;
    height: 52px !important;
    margin: 1rem 0 !important;
    padding: 2rem !important;
  }

  .select-form-control {
    font-size: 22px !important;
    height: 67px !important;
    margin: 1rem 0 !important;
  }

  .field-con {
    right: 15px;
    top: 25px;
  }
  .form-check-input {
    width: 2em;
    height: 2em;
    margin-right: 5px;
  }
  .form-check .form-check-input {
    margin-top: 4px;
  }

  .form-check-label {
    font: normal normal normal 20px Airbnb Cereal App Book;
  }
  .sign--up--button,
  .signup__loading__button {
    font: normal normal bold 22px Airbnb Cereal App Bold;
    height: 50px;
    /* width: 35%; */
  }
  .bottom {
    margin-top: 15px;
  }
  .already,
  .bottom-link,
  .bottom-link:visited,
  .bottom-link:hover,
  .bottom-link:active {
    font: normal normal normal 20px Airbnb Cereal App Book;
    color: var(--button-color);
  }
}
@media (max-width: 411px) {
  .form-check .form-check-input {
    margin-top: 4px;
  }
  .select-form-control {
    display: block;
    width: 40%;
  }
}
@media (max-width: 375px) {
  .form-check .form-check-input {
    margin-top: 4px;
  }
  .select-form-control {
    display: block;
    width: 40%;
  }
}
@media (max-width: 360px) {
  .signup-right--section--h2 {
    margin-bottom: 0px;
  }
}
@media (max-width: 320px) {
  .signup-right--section--h2 {
    margin-bottom: 0px;
  }
  .signup-right--section--p {
    white-space: normal;
  }
}
@media (max-width: 280px) {
  /* .signup-form-container .input-pan {
    top: 25px;
    left: 5px;
    font-size: 12px !important;
  } */
  .select-form-control {
    display: block;
    width: 40%;
  }
  .signup-form-control,
  .select-form-control {
    height: 42px !important;
  }
  .field-con {
    right: 5px;
    top: 15px;
  }
  .form-check-label {
    font: normal normal normal 12px Airbnb Cereal App Book;
  }
  .sign--up--button,
  .signup__loading__button {
    height: 40px;
  }
  .already,
  .bottom-link,
  .bottom-link:visited,
  .bottom-link:hover,
  .bottom-link:active {
    font: normal normal normal 12px Airbnb Cereal App Book;
  }
}

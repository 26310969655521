.avatar-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  min-height: 15vh;
}

.avatar-options > section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.avatar-options > section:first-child img {
  width: 25px;
  height: 22.22px;
}

.avatar-options > section:last-child img {
  width: 20px;
  height: 20px;
}

.avatar-options .avatar-input {
  border: none;
  color: transparent;
  width: 25px;
  height: 20px;
}

.avatar-options .avatar-input::-webkit-file-upload-button {
  visibility: hidden;
}

.avatar-options .avatar-input::before {
  display: inline-block;
  content: url("../../../asset/ic_folder_24px.png");
  vertical-align: middle;
  user-select: none;
  -webkit-user-select: none;
  outline: none;
  height: 100%;
  position: relative;
  z-index: 2;
  cursor: pointer;
  max-width: 100%;
  height: auto;
}

.avatar-options label {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #ffffff;
  margin-top: 0.625rem;
  font-weight: 500;
}

@media only screen and (min-width: 600px) {
  .avatar-options {
    width: 65%;
  }

  .avatar-options label {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .avatar-options .avatar-input {
    width: 37.5px;
    height: 30px;
  }

  .avatar-options > section:first-child img {
    width: 33.33px;
    height: 30px;
  }

  .avatar-options > section:last-child img {
    width: 30px;
    height: 30px;
  }
}
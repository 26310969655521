.faqs__tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  transition: ease-in 0.2s;
}
/* .tabs {
  padding: 10px !important;
  text-align: center;
  background: transparent;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  white-space: nowrap;
} */
.faqs__tab {
  margin: 15px 30px;
  font-size: 1.3rem;
  font-family: "Airbnb Cereal App Book";
  color: #1b1c1d;
  border: none;
  background-color: transparent;
}
.active-faq-tabs {
  border-radius: 10px;
  padding: 15px 30px;
  border: 2px solid #868593;
}

/* .active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
} */

.content-tabs {
  flex-grow: 1;
}
.faqs__content {
  width: 100%;
  height: 100%;
  display: none;
  text-align: center;
  transition: all 2s ease-in-out;
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 2s ease-in-out;
}
.content h2 {
  padding: 0px 0 5px 0px;
}

.active-content {
  display: block;
  transition: all 2s ease-in-out;
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 2s ease-in-out;
}
.content__container {
  margin: 50px 0px;
}

@media (max-width: 767px) {
  .faqs__tabs {
    margin: 10px 0;
  }
  .faqs__tab {
    margin: 15px;
  }
}

@media screen and (max-width: 520px) {
  .faqs__tab {
    margin: 8px;
  }
  .faqs__tab {
    font-size: 1rem;
  }
}

@media screen and (max-width: 420px) {
  .faqs__tab {
    margin: 5px;
  }
  .active-faq-tabs {
    padding: 12px 20px;
  }
}

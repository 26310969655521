.avatar-list {
  display: flex;
  position: relative;
  width: 100%;
  height: 130px;
  overflow-x: hidden;
}

.avatar-choice-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  visibility: hidden;
}

.show-cover {
  z-index: 999;
  opacity: 1;
  visibility: visible;
}

.avatar-item {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.avatar-choice .avatar-item img:first-child {
  border-radius: 50%;
  border: 2px solid;
} 

.avatar-item p {
  color: #fdfdfd;
  text-transform: capitalize;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 500;
  margin-top: 0.2rem;
}

.avatar-choice .ticked {
  position: absolute;
  top: 0px;
  right: -2px;
  opacity: 0;
  visibility: hidden;
  display: none;
  z-index: 1000;
}

.avatar-choice .ticked.display {
  opacity: 1;
  visibility: visible;
  display: inline-block;
}

/* Bottom Buttons */ 

.avatar-choice .btn-container {
  padding: 0.625rem 0.5rem;
  text-align: right;
}

.avatar-choice .btn-container > div button {
  background-color: transparent;
  color: #fdfdfd;
  cursor: pointer;
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  border: 1px solid #FDFDFD;
}

.avatar-choice .btn-container .confirm-selection button {
  display: inline-block;
  border-radius: 10px;
  padding: 0.375rem 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
}

.avatar-choice .slide-controls button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(253, 253, 253, 0.3);
}

.avatar-choice .slide-controls button .controls-icon {
  fill: #fdfdfd;
}

.avatar-choice .btn-container > div > button:first-child {
  margin-right: 1rem;
}

.avatar-choice .confirm-selection button:last-child {
  background-color: #FDFDFD;
  color: #1B1C1D;
}

.avatar-choice .slide-controls button:disabled {
  border-color: rgba(253, 253, 253, 0.3);
}

.avatar-choice .slide-controls button:disabled .controls-icon {
  fill: rgba(253, 253, 253, 0.3);
}

@media only screen and (min-width: 600px) {
  .avatar-choice .ticked {
    right: -4px;
    top: 5px;
  }

  .avatar-choice .confirm-selection button {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
}

@media only screen and (min-width: 768px) {
  .avatar-list {
    height: 200px;
  }

  .avatar-item p {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .avatar-choice .slide-controls button {
    width: 46px;
    height: 46px;
  }

  .avatar-choice .btn-container .confirm-selection button {
    padding: 0.625rem 2.5rem;
  }
}
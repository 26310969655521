.beneficiary__dey__section__sub{
    border: 1px solid #EFEFEF;
    padding: 50px 10px 10px;
    margin-bottom: 40px;
    border-radius: 5px;
}

@media (max-width: 500px) {
    .beneficiary__dey__section__sub{
        padding: 30px 10px 10px;
    }
  }
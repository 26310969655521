.trade-terms {
  max-width: 726px;
  width: 100%;
  padding: 0 10px;
  border: 2px solid #C2C2C2;
  border-radius: 4px;
  color: #1b1b1b;
  margin: 1rem auto 1rem;
  position: relative;
  -webkit-box-shadow: 0.5rem 0.5rem #ffffff,
                      0.5625rem 0.5625rem #C2C2C2,
                      0.5625rem 0.4375rem #C2C2C2,
                      0.4375rem 0.5625rem #C2C2C2;                                                                    
  -moz-box-shadow: 0.5rem 0.5rem #ffffff,
                   0.5625rem 0.5625rem #C2C2C2,
                   0.5625rem 0.4375rem #C2C2C2,
                   0.4375rem 0.5625rem #C2C2C2;                                                                
  box-shadow: 0.5rem 0.5rem #ffffff,
              0.5625rem 0.5625rem #C2C2C2,
              0.5625rem 0.4375rem #C2C2C2,
              0.4375rem 0.5625rem #C2C2C2;
}

.trade-terms .terms-header,
.trade-terms .terms-content {
  max-width: 493px;
  margin: 0 auto; 
}

.trade-terms .terms-header {
  padding: 1rem 0 1rem;
  text-align: center;
}

.trade-terms h4 {
  font-size: 0.875rem;
  line-height: 1.1875rem;
  font-weight: bold;
}

.trade-terms .terms-content {
  margin-bottom: 0.5rem;
}

.trade-terms p {
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: 600;
  margin-bottom: 0.5625rem!important;
}

.trade-terms .terms-pen {
  display: inline-block;
  position: absolute;
  left: -0.625rem;
  top: -0.625rem;
  width: 7%;
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.8s ease-out;
  transition: all 0.8s ease-out;
}

.trade-terms .btn-wrapper {
  text-align: center;
  padding: 1.5rem 0;
  position: absolute;
  right: 0;
  bottom: -6rem;
}

@media only screen and (min-width: 450px) {
  .trade-terms .terms-pen {
    width: 5%;
  }
}

@media only screen and (min-width: 768px) {
  .trade-terms {
    padding: 1.5rem 7.5px;
    -webkit-box-shadow: 1rem 1rem #ffffff,
                        1.0625rem 1.0625rem #C2C2C2,
                        1.0625rem 0.9375rem #C2C2C2,
                        0.9375rem 1.0625rem #C2C2C2;
    -moz-box-shadow: 1rem 1rem #ffffff,
                     1.0625rem 1.0625rem #C2C2C2,
                     1.0625rem 0.9375rem #C2C2C2,
                     0.9375rem 1.0625rem #C2C2C2;   
    box-shadow: 1rem 1rem #ffffff,
                1.0625rem 1.0625rem #C2C2C2,
                1.0625rem 0.9375rem #C2C2C2,
                0.9375rem 1.0625rem #C2C2C2;
  }

  .trade-terms .terms-header {
    text-align: left;
  }

  .trade-terms h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  .trade-terms .terms-content {
    margin-bottom: 4rem;
  }

  .trade-terms p {
    font-size: 1rem;
  }

  .trade-terms .terms-pen {
    width: 100%;
    left: -1.5rem;
    top: -1.5rem;
  }

  .trade-terms .btn-wrapper {
    position: static;
  }
}
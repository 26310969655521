.sidebar {
  position: fixed;
  max-width: 31.25rem;
  top: 0;
  left: 0;
  display: none;
  _-webkit-transform: translateX(-100%);
  _-moz-transform: translateX(-100%);
  _transform: translateX(-100%);
  _-webkit-transition: transform 0.5s ease-out;
  _-moz-transition: transform 0.5s ease-out;
  _transition: transform 0.5s ease-out;
  min-height: 100vh;
}

.sidebar-wrapper {
  max-width: 31.25rem;
  width: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  background-color: rgb(27, 28, 29);
  padding: 2rem;
}

.show-sidebar {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.logo {
  width: 100.25px;
  height: 40px;
  margin: 0 1rem 2rem;
}

.logo img {
  max-width: 100%;
  height: auto;
}

.sidebar-footer {
  max-width: 12.5rem;
  height: 11.25rem;
  margin: 2rem 1rem 0;
  align-self: flex-start;
  display: flex;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
}
.sidebar-footer .carousel-indicators {
  margin-bottom: 0;
  background-color: transparent;
}

.sidebar-footer .carousel-indicators button {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #BBD1EB33;
}

.sidebar-footer .carousel-indicators button.active {
  background-color: #1B1C1D;
}

.sidebar-footer-content {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
}

.sidebar-footer div {
  background-color: #FFFFFF;
  border-radius: 15px;
}

.sidebar-footer img {
  width: 100%;
}

.sidebar-footer p {
  padding: 0.625rem 0 1.2rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.875rem;
  font-weight: 600;
  text-align: center;
  font-family: "Airbnb Cereal App Medium";
}

@media only screen and (min-width: 600px) {
  .sidebar {
    max-width: 22.5rem;
  }

  .sidebar-wrapper {
    max-width: 22.5rem;
  }
}

@media only screen and (min-width: 992px) {
  .sidebar {
    flex-basis: 22.5rem;
    display: block;
    position: relative;
    _-webkit-transform: translateX(0);
    _-moz-transform: translateX(0);
    _transform: translateX(0);
  }
  
  .sidebar-wrapper {
    max-width: 320px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: visible;
  }
}
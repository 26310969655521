.set__pin__main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 100px 40px 40px; */
}
.set__pin__main__image{
    width: 100px;
    height: 101.95px;
}
.set__pin__main__image img{
    width:100%;
    height:100%;
}
.set__pin__main__image__text{
    text-align: center;
    color:#1B1C1D;
    font-size: 1rem;
    font-family: "Airbnb Cereal App Book";
    margin-top:15px;
    line-height: 2;
}

.tags-input-container {
    border: 1px solid #D3D3D3;
    padding: 10px;
    border-radius: 5px;
    width: min(80vw, 600px);
    margin: 0.5em auto 1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
  }
  
  .tag-item {
    background-color: #EFEEFF;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .setpin-input{
      flex-grow: 1;
      padding: .5em;
      border:none;
      outline: none;
      background-color: transparent;
      color: black;
  }
  /* .setpin-input::placeholder{
      color: black;
  } */
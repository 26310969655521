.change__pin,
.feedback__component {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  background-color: #fff;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 10px;
  width: 674px;
  /* height: 741px; */
  /* margin: 40px; */
}
.change__pin {
  /* i'd remove this margin when i activate the feedback component later on  */
  margin: 100px auto 50px; 
  padding: 70px 54px;
}
.feedback__component {
  height: 50px;
  margin: 100px auto 20px;
}
.feedback__home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.change__pin__form__icon {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -35px;
  margin-bottom: 20px;
}
input[type="password"] {
  -webkit-text-security: disc;
}
.change__pin__form__text {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Book";
}
.change__pin__form__text span {
  font-family: "Airbnb Cereal App Bold";
}
.change__pin__form__label {
  color: var(--button-color);
  font-size: 1.25rem;
  font-family: "Airbnb Cereal App Bold";
}
.change__pin__form__input {
  margin: 20px 0px;
}
.change__pin__form__input input {
  width: 100%;
  display: block;
  padding: 0.625rem 1.25rem 0.625rem 0.8rem;
  border-radius: 5px;
  border: 1px solid rgb(112, 112, 112);
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  color: #1b1b1b;
  font-family: "Airbnb Cereal App Medium";
  height: 60px;
}
.change__pin__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
  gap: 40px;
}
.change__pin__cancel__button {
  border: none;
  background-color: transparent;
  color: #1b1c1d;
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Medium";
}
.change__pin__button {
  color: var(--main-white);
  text-align: center;
  font-size: 1rem;
  padding: 18px 35px;
  font-family: "Airbnb Cereal App Bold";
  background-color: var(--button-color);
  border-radius: 10px;
}

@media screen and (max-width: 767px) {
  .change__pin {
    padding: 35px 5px;
    width: 340px;
    /* margin: 70px auto 20px; */
  }
  .feedback__component {
    width: 340px;
    padding: 10px 5px;
  }
  .feedback__component {
    margin: 70px auto 15px;
  }
  .change__pin__form__icon {
    margin-bottom: 10px;
  }
  .change__pin__form__input input {
    height: 50px;
  }
}

/*  ecode modal */

.ecode-modal_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: opacity transform;
  -moz-transition-property: opacity transform;
  transition-property: opacity transform;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

.show-modal {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.ecode-modal {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 1rem;
  height: 100%;
  border-radius: 10px;
}

.ecode-modal_header {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  text-align: right;
  padding: 0.5rem 0;
}
.ecode-modal_header p {
  background-color: transparent;
  color: #fdfdfd;
  font-size: 1.25rem;
  font-family: "Airbnb Cereal App Bold";
}

.ecode-modal_header button {
  background-color: transparent;
  color: #fdfdfd;
  border: none;
  outline: none;
}

.ecode-modal_content {
  padding: 1rem;
  align-self: center;
  position: relative; 
}

.ecode__details__section__form h3{
padding: 5px 0;
 width: min(80vw, 600px);
margin:  auto;
 display: flex;
align-items: center;
 color: #fdfdfd;
font-family: "Airbnb Cereal App Medium";
font-size: 1rem;  
}

.ecode__details__section__button__section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: min(80vw, 600px);
  margin: 0.5em auto 1em;
}
.ecode__details__section__button {
  color:#1B1C1D;
  text-align: center;
  font-size: 1rem;
  padding: 15px 35px;
  font-family: "Airbnb Cereal App Book";
  background-color:#FDFDFD;
  border-radius: 10px;
  margin-top: 25px;
  border: 1px solid transparent;
}

@media only screen and (min-width: 992px) {
  .ecode-modal {
    padding: 5rem;
  }
}


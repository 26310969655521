.sidebar-list {
  display: flex;
  flex-direction: column;
}

.sidebar-item {
  margin-bottom: 0.2rem;
}

.sidebar-link,
.sidebar-item .dropdown-toggler,
.sublink_link 
{
  padding: 1.2rem 1rem;
  border-radius: 15px;
  display: inline-block;
  cursor: pointer;
  font-family: "Airbnb Cereal App Bold";
}

.sidebar-link:hover,
.sublink_link:hover,
.dropdown-toggler:hover {
  background-color: #262A2D!important;
}

.sidebar-link.active,
.sublink_link.active{
  background-color: #262A2D!important;
}

.sidebar-item,
.sidebar-link,
.sublink_item,
.sublink_link {
  color: #D3D3D3!important;
  text-decoration: none;
  list-style: none;
  white-space: nowrap;
}

.sidebar-link {
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  grid-gap: 0 1rem;
  align-items: center;
}

.sidebar-item .dropdown-toggler {
  display: grid;
  grid-template-columns: 1.5rem 1fr auto;
  grid-gap: 0 1rem;
  align-items: center;
}

.dropdown-content {
  display: grid;
  _grid-template-columns: auto 1fr;
  padding: 0 1.7rem;
  position: relative;
}

.dropdown-content img {
  position: absolute;
  top: 0;
  left: 2rem;
  height: 8rem;
}

.sublink_list {
  align-self: flex-end;
}

.sublink_item:first-child {
  margin-top: 0.5rem;
}

.sublink_item:last-child {
  margin-top: 1.5rem;
}
.sublink_item {
  margin-left: 1rem;
}

@font-face {
  font-family: "Airbnb Cereal App Book";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Book"),
    url("./fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBook.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Light";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Light"),
    url("./fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealLight.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Medium"),
    url("./fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealMedium.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Bold"),
    url("./fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBold.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Extra Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Extra Bold"),
    url("./fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealExtraBold.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Black";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Black"),
    url("./fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBlack.woff")
      format("woff");
}

.App {
  display: inline-flex;
  flex-direction: row;
  min-height: 100vh;
}
.MainApp {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.verification {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  height: 100vh;
  width: 100vw;
}

.custom-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  --columns: 6;
}
.gen__container {
  width: 95%;
  margin: 0 auto;
  padding: 0 1.5rem;
  /* height:100vh; */
}

[class*="col-"] {
  --width: 6;
  --initial-basis: calc(var(--width) / var(--columns) * 100%);
  --gap: calc((var(--columns) - var(--width)) * 0.5%);
  flex-basis: calc(var(--initial-basis) - var(--gap));
  padding: 0 7.5px;
}

.column {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
}

.btn {
  display: inline-block;
  border: 1px solid #1b1c1d;
  border-radius: 10px;
  background-color: transparent;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.card {
  border: 1px solid #efefef;
  background-color: #ffffff;
  padding: 1.2rem 0.8rem;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent !important ;
  border-bottom: 0 !important;
  padding: 0 !important;
}
.card-header h3 {
  font-family: "Airbnb Cereal App Bold";
}

.card-body {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
}

.card-btn {
  display: flex;
}

.card-btn .btn{
  flex: 1;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 0.8rem;
  font-family: "Airbnb Cereal App Bold";
}

.wallet__body .btn {

  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 0.8rem;
  font-family: "Airbnb Cereal App Bold";
}

.card-btn .btn:first-child {
  margin-right: 0.5rem;
  color: rgb(27, 28, 29);
}
.deposit-btn a,
/* .trade__btn a, */
.deposit-btn a:visited,
/* .trade__btn a:visited, */
.deposit-btn a:active {
  color: #1b1c1d;
  text-decoration: none;
  font-family: "Airbnb Cereal App Bold";
}
.trade__btn{
  width: 50%;
  margin: auto;
}

.deposit-btn:hover {
  background-color: rgb(27, 28, 29);
  color: #ffffff !important;
}

.custom-card-footer a {
  color: rgb(27, 28, 29);
  font-family: "Airbnb Cereal App Medium";
}

.custom-card-footer a:hover {
  text-decoration: none;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.label-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.form-group {
  position: relative;
  display: grid;
}

.form-group select {
  width: 100%;
  display: block;
  padding: 0.625rem 1.25rem 0.625rem 0.8rem;
  border-radius: 4px;
  border: 1px solid rgb(112, 112, 112);
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  color: #1b1b1b;
  font-family: "Airbnb Cereal App Medium";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-group img {
  position: absolute;
  right: 0.7rem;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  /* z-index: -1; */
}

.select-icon {
  display: inline-block;
  margin-left: 1rem;
}

@media only screen and (min-width: 600px) {
  .col-1,
  .col-2,
  .col-3 {
    --width: 3;
  }

  .col-4 .col-5,
  .col-6 {
    --width: 6;
  }
  .gen__container {
    max-width: 95%;
  }
}

@media only screen and (min-width: 1100px) {
  .col-1 {
    --width: 1;
  }

  .col-2 {
    --width: 2;
  }

  .col-3 {
    --width: 3;
  }

  .col-4 {
    --width: 4;
  }

  .col-5 {
    --width: 5;
  }

  .col-6 {
    --width: 6;
  }
}

.bloc-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #efefef;
  border-radius: 5px;
  height: 60px;
  width: 60%;
  margin: auto;
}
.tabs {
  padding: 8px 10px !important;
  text-align: center;
  width: 40%;
  background-color: #efefef;
  cursor: pointer;
  border: none;
  box-sizing: content-box;
  position: relative;
  outline: none;
  white-space: nowrap;
  -webkit-transition: background-color 0.3s ease-in;
  -moz-transition: background-color 0.3s ease-in;
  transition: background-color 0.3s ease-in;
}

.active-tabs {
  background: #fdfdfd;
  border-radius: 3px;
  transition: 0.3s;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
}

.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 50px 30px 0px;
  width: 100%;
  height: 100%;
  display: none;
  text-align: center;
}
.content a,
.content a:visited,
.content a:active,
.content a:hover {
  text-decoration: none;
}
.active-content {
  display: block;
}
.bloc-tabs button {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Medium";
}
.tranfer__main {
  width: 100%;
}

.transfer__form {
  width: 70%;
  margin: auto;
}

.transfer__details__section__form {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  padding: 1.5rem 2.5rem;
  flex-direction: column;
}
.transfer__details__section__account-label {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  font-size: 1rem;
}
.transfer__details__section__account-label span {
  font-size: 0.75rem;
  margin-left: 10px;
}
.transfer__details__section__input {
  width: 100%;
  display: block;
  padding: 0.625rem 1.25rem 0.625rem 0.8rem;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  color: #1b1b1b;
  font-family: "Airbnb Cereal App Medium";
  height: 60px;
}
.transfer__details__section__form-group {
  margin-bottom: 15px;
}
.transfer__details__section__button__section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.transfer__details__section__button {
  color: var(--main-white);
  text-align: center;
  font-size: 1rem;
  padding: 20px 30px;
  font-family: "Airbnb Cereal App Book";
  background-color: var(--button-color);
  border-radius: 10px;
  margin-top: 25px;
}
.toggle__section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.toggle__section__text {
  color: #1b1c1d;
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Medium";
  margin-left: 15px;
}
.transfer__form__response {
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem;
  padding: 1.5rem 2.5rem;
  flex-direction: column;
  border: 1px solid #efefef;
}

.transfer__form__response__first-text {
  color: #a8a8a8;
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Book";
}
.transfer__form__response__second-text {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Bold";
  margin-top: 10px;
  line-height: 1.5;
}
.no__beneficiary__section__first_text {
  color: #6f6f6f;
  font-size: 1.25rem;
  font-family: "Airbnb Cereal App Bold";
}
.no__beneficiary__section__second_text {
  color: #a8a8a8;
  font-size: 1.25rem;
  font-family: "Airbnb Cereal App Book";
  margin-top: 8px;
}
.no__beneficiary__section__text {
  margin: 20px 0px;
}
.no__beneficiary__section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 10px 20px;
  margin: 50px 0px;
}
.no__beneficiary {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6c63ff;
  height: 200px;
  width: 200px;
  border-radius: 50%;
}
.beneficiary__dey__section {
  width: 40%;
  margin: auto;
}
.beneficiary__dey__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 15px 20px;
  margin: 20px;
  border: 1px solid #efefef;
  border-radius: 5px;
  gap:20px;
}
.beneficiary__dey__card__text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}
.beneficiary__dey__card__image {
  width: 63px;
  height: 63px;
}
.beneficiary__dey__card__img {
  width: 100%;
  height: 100%;
}
.beneficiary__dey__card__text__first_text {
  color: #6f6f6f;
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Medium";
}
.beneficiary__dey__card__text__second_text {
  color: #1b1c1d;
  font-size: 1.125rem;
  font-family: "Airbnb Cereal App Bold";
}
/* .divider__image__section {
} */
.divider__image {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 405px;
  height: 26px;
}
.divider__img {
  width: 100%;
  height: 100%;
}
@media (min-width: 910px) and (max-width: 1282px) {
  .beneficiary__dey__section {
    width: 60%;
    margin: auto;
  }
}
@media (max-width: 900px) {
  .transfer__form {
    width: 100%;
    margin: auto;
  }
  .bloc-tabs {
    height: 60px;
    width: 100%;
    margin: auto;
  }
  .transfer__details__section__form {
    padding: 1rem 0.5rem;
  }
  .transfer__details__section__form-group {
    margin-bottom: 20px;
  }
  .no__beneficiary {
    height: 150px;
    width: 150px;
  }
  .no__beneficiary__section {
    margin: 30px 0px;
  }

  .no__beneficiary__section__first_text,
  .no__beneficiary__section__second_text {
    font-size: 1rem;
    margin-top: 0;
  }
  .beneficiary__dey__card {
    padding: 10px 15px;
  }
  .beneficiary__dey__card__image {
    width: 45px;
    height: 45px;
  }
  .beneficiary__dey__section {
    width: 100%;
    margin: auto;
  }
  .divider__image {
    width: 240px;
    height: 20px;
  }
  .beneficiary__dey__card__text__second_text {
    font-size: 0.825rem;
  }
}
@media (max-width: 500px) {
  .content {
    padding: 40px 10px 0px;
  }
  .bloc-tabs button {
    font-size: 0.75rem;
  }
  .tabs {
    width: 40%;
  }
  .toggle__section__text {
    font-size: 0.75rem;
    margin-left: 5px;
    white-space: nowrap;
  }
  .princess {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
  .transfer__details__section__account-label span {
    margin: 5px 0px 0px 5px;
  }
  .transfer__form__response {
    padding: 1.5rem;
  }
}
@media screen and (max-width: 280px) {
  .toggle__section__text {
    white-space: normal;
  }
}
/* @media only screen and (max-width: 300px) {
  .tabs {
    padding:  8px 5px !important;
   
  }
} */
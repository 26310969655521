.toll__details__section,
.wallet__balance__component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.toll__details__section__image {
  width: 48px;
  height: 48px;
  margin: 40px 0;
}
.toll__details__section__image img {
  width: 100%;
  height: 100%;
}
.wallet__balance__component {
  background-color: #efefef;
  border: 1px solid #6c63ff;
  border-radius: 5px;
  padding: 10px 40px;
  margin:auto;
 width: 200px;
 height:75px;
}


.wallet__balance {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Book";
  font-size: 0.75rem;
}
.wallet__balance__amount {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  font-size: 0.875rem;
}
.toll__details__section__form {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  padding: 1.5rem 2.5rem;
  flex-direction: column;
}
.toll__details__section__account-label {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  font-size: 1rem;
}
.toll__details__section__input {
  width: 100%;
  display: block;
  padding: 0.625rem 1.25rem 0.625rem 0.8rem;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  color: #1b1b1b;
  font-family: "Airbnb Cereal App Medium";
  height: 60px;
}
.toll__details__section__form-group {
  margin-bottom: 15px;
}
.toll__details__section__button__section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.toll__details__section__button {
  color: var(--main-white);
  text-align: center;
  font-size: 1rem;
  padding: 20px 30px;
  font-family: "Airbnb Cereal App Book";
  background-color: var(--button-color);
  border-radius: 10px;
  margin-top: 25px;
}

.withdraw__summary__main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 130px;
  margin: 50px auto;
  padding: 40px;
  border: 1px solid #efefef;
  width:90%
}

.withdraw__summary__main__section h4 {
  font-size: 1.25rem;
}

.withdraw__summary__main__section,
.withdraw__summary__main__feedback__section {
  width: 80%;
  margin: auto;
}
.withdraw__summary__main__feedback__section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.withdraw-summary {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin: 0.5rem 0 1.5rem;
  background-color: #f8f8f8;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 2rem 3rem;
}

.withdraw-summary-content {
  grid-row: 2 / 3;
}

.withdraw-summary-content p {
  display: flex;
  align-items: center;
  margin-bottom: 1rem !important;
}

.withdraw-summary-content p span {
  flex: 1;
}

.withdraw-summary-content p span:first-child,
.withdraw-summary-content p span:last-child {
  font-size: 0.75rem;
  line-height: 1.375rem;
}

.withdraw-summary-content p span:first-child {
  color: #a8a8a8;
  font-family: "Airbnb Cereal App Medium";
}

.withdraw-summary-content p span:last-child {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
}

.withdraw-summary-content p:first-child > span:last-child {
  text-transform: uppercase;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}
.withdraw__summary__main__section .card-img-wrapper {
  width: 150px;
  height: 150px;
  margin: auto;
  padding: 1rem 0;
  align-self: center;
  justify-self: center;
  grid-row: 1 / 2;
}
.withdraw__summary__main__section .card-img-wrapper img {
  width: 100%;
  height: 100%;
}
.withdraw__summary__main__feedback__section p {
  color: #a8a8a8;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Book";
}
.withdraw__summary__main__feedback__section h4 {
  margin-top: 10px;
  text-align: center;
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Medium";
}

@media only screen and (max-width: 992px) {
  .withdraw__summary__main {
    gap: 80px;
    margin: 40px 20px;
    padding: 40px 20px 50px;
  }
  .withdraw__summary__main__section,
  .withdraw__summary__main__feedback__section {
    width: 100%;
    margin: auto;
  }
  .withdraw__summary__main__section h4 {
    font-size: 1rem;
  }
  .withdraw-summary {
    padding: 1rem;
  }
  .withdraw-summary-content p {
    margin-bottom: 0.7rem !important;
  }
  .withdraw__summary__main__section .card-img-wrapper {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (min-width: 600px) {
  /* .withdraw__summary__main__section h4 {
    font-size: 1.25rem;
  } */

  .withdraw-summary {
    grid-template-columns: 1fr auto;
  }

  .withdraw-summary-content,
  .withdraw__summary__main__section .card-img-wrapper {
    grid-row: 1 / 2;
  }

  .withdraw-summary-content {
    grid-column: 1 / 2;
  }

  .withdraw-summary-content p span:first-child,
  .withdraw-summary-content p span:last-child {
    font-size: 1rem;
  }

  .withdraw__summary__main__section .card-img-wrapper {
    grid-column: 2 / 3;
  }
}

.hottest-rate {
  _flex: 3;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: rgb(248, 248, 248);
  height: 470px;
}

.hottest-rate-header {
  display: flex;
  align-items: center;
}
.hottest-rate-header span {
  margin-top: -20px;
}
.hottest-rate-header h3 {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: bold;
  font-family: "Airbnb Cereal App Bold";
}

.hottest-rate-header h3 {
  margin-right: 0.5rem;
}

.hottest-rate-item {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 0 1rem;
  padding: 0.5rem 0;
}

.rating-app-store {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: bold;
  color: rgb(27, 28, 29);
  font-family: "Airbnb Cereal App Medium";
}

.rating-app-store p:last-child {
  font-size: 1rem;
  color: rgb(168, 168, 168);
  font-weight: normal;
}

.rating-country p {
  font-size: 1rem;
  line-height: 1.375rem;
  color: rgb(27, 28, 29);
  font-weight: bold;
  font-family: "Airbnb Cereal App Bold";
}

.appstore-icon-wrapper {
  width: 50px;
  height: 50px;
}
.appstore-icon-wrapper img {
  width: 100%;
  height: 100%;
}
.country-icon-wrapper {
  width: 30%;
  margin-left: auto;
}

.hottest-rate-footer {
  padding: 0.4rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.hottest-rate-footer p {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: medium;
  text-decoration: underline;
  color: rgb(27, 28, 29);
}

@media (max-width: 600px) {
  .hottest-rate {
    height: 440px;
  }
  .hottest-rate-header {
    margin-bottom: 1rem;
  }
  .hottest-rate-header h3 {
    margin-bottom: 0 !important;
  }
  .appstore-icon-wrapper {
    width: 35px;
    height: 35px;
  }
}

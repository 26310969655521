.submenu-overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.6); */
  z-index: 5;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
}
.submenu__image{
  width: 25px;
  height:25px;
}
.submenu__image img{
  width: 100%;
  height: 100%;
}
.show {
  visibility: visible;
  opacity: 1;
}

.submenu {
  position: relative;
  bottom: 0;
  right: 0;
  overflow-x: auto;
  overflow-y: hidden;
  /* max-width: 550px; */
  width: 100%;
  height: 100%;
  /* padding: 1.7rem 0; */
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  opacity: 0;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  background: rgb(27, 28, 29);
  display: grid;
  grid-template-rows: 160px 1fr;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.show-submenu {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  opacity: 1;
}

.submenu-header {
  width: 40%;
  padding: 0 2rem;
}

.submenu-list {
  display: flex;
  justify-content: space-evenly;
  /* flex-direction: column; */
}

.submenu-item {
  list-style: none;
}

.submenu-item .dropdown-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-right: 0;
  padding: 1rem 0;
  cursor: pointer;
}
/* .submenu-item {
  font-size: 1rem;
  line-height: 1.875rem;
  color: #FDFDFD;
  text-decoration: none;
  text-align: center;
  font-weight: bolder;
} */
.submenu-link span,
.submenu-link span:hover,
.submenu-link span:active{
  font-size: 8px;
  line-height: 1.875rem;
  color: #FDFDFD;
  text-decoration: none;
  text-align: center;
  font-weight: bolder;
  text-decoration: none;
}

.submenu-link {
  padding: 1rem 0.8rem;
  /* padding: 1rem 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* position: relative; */
  align-items: center;
  text-decoration: none;
}

/* .submenu-link.active::before,
.submenu-link.active::after {
  flex: 1;
  content: "";
  background-color: #FDFDFD;
  height: 1px;
  margin: auto 0;
} */

/* .submenu-link.active::before {
  margin-right: 2rem;
} */

/* .submenu-link.active::after {
  margin-left: 2rem;
} */

.dropdown_link,
.dropdown_link:hover,
.dropdown_link:active{
  font-size: 0.875rem;
  color: #D3D3D3;
  text-decoration: none;
  padding: 0.5rem 0;
  display: inline-block;
}


@media only screen and (max-width: 992px) {
  /* .submenu,
  .submenu-overlay{
  margin-top: 6.25rem;
  } */
}

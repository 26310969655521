.avatar__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.avatar__list__section__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 115px;
  margin: auto;
  /* opacity:0.2; */
  /* padding: 30px 30px 0px; */
}

.avatar__list__sub__section {
  background-color: #efefef;
  width: 280px;
  height: 130px;
  border-radius: 10px;
  position: relative;
}
.avatar__list__sub__sub__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.avatar__list__sub__sub__section .pri {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.avatar__list__section__imag,
.avatar__list__section__image img {
  height: 100%;
  width: 100%;
}
.avatar__list__section__text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  flex-direction: column;
  margin: 10px 0;
}
.avatar__list__section__first__text {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Medium";
}
.avatar__list__section__second__text {
  color: #6f6f6f;
  font-size: 0.75rem;
  font-family: "Airbnb Cereal App Book";
}
.avatar__list__section__section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.avatar__list__section__section__image {
  display: flex;
  margin-left: 5px;
  width: 11px;
  height: 11px;
}
.avatar__list__section__section__img {
  width: 100%;
  height: 100%;
}
.avatar__list__section {
  /* direction: ltr; */
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  z-index:1;
}
.avatar__list__main {
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* direction: rtl; */
}
::-webkit-scrollbar {
  width: 18px;
}
::-webkit-scrollbar-button {
  width: 100px;
  height: 100px;
}
::-webkit-scrollbar-thumb {
  height: 5px;
  background: #efefef;
  border-right: 13px white solid;
  background-clip: padding-box;
  margin-right: 10px;
}
.avatar_span {
  display: none;
}
.avatar_span_hide {
  display: block;
}
.avatar__list__sub__section__image {
  width: 30px;
  height: 30px;
  background: #efefef;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar__list__sub__section__image img {
  width: 100%;
  height: 100%;
}
.avatar__list__sub__sub__section__number {
  background: #1b1c1d 0% 0% no-repeat padding-box;
  border: 2px solid #20c320;
  color: #fff;
  font-size: 0.875rem;
  border-radius: 50%;
  padding: 1px 7px;
}
.avatar__list__sub__sub__section::after {
  content: "";
  position: absolute;
  width: 150px;
  height: 0px;
  border: 1px solid #efefef;
  left: 6rem;
}
.avatar__list__sub__sub__section__level {
  font-size: 0.825rem;
  z-index: 2000 !important;
  background-color: transparent;
}

.avatar-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* background-color: #efefef; */
  background: #cec9c9;
  opacity: 1;
  /* z-index: 999; */
  /* opacity: 1; */
  display: block;
  visibility: visible;
  transition: opacity 0.4s ease-out;
  -moz-transition: opacity 0.4s ease-out;
  -webkit-transition: opacity 0.4s ease-out;
}

.avatar-image-clear-overlay {
  /* display: none; */
  /* background-color: red; */
  opacity: 0;
  /* visibility: hidden; */
}
.avatar-image-clear-overlay-content {
  position: absolute;
  width: 18px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  opacity:1;
}
.avatar-image-clear-overlay-content img {
  width: 100%;
  height: 100%;

}
/* .chika{
  margin-bottom: 10px;
} */
.avatar-profile__mobile__loading__button{
  /* border: 2px solid var(--primary--color); */
  border-radius: 10px;
  /* margin: auto ; */
  padding: 10px 50px;
  /* padding: 10px; */
  /* height: 50px */
  width: 100%;
  /* width: 100%; */
  background-color: transparent;
  text-align: center;
}

@media only screen and (max-width: 1400px) {
  .avatar__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .avatar_span {
    display: block;
    white-space: nowrap;
  }
  .avatar_span_hide {
    display: none;
  }
}
/* @media only screen and (max-width: 700px) {
  .avatar__list__section__image {
    width: 120px;
    height: 170px;
  }
} */
@media only screen and (max-width: 768px) {
  .avatar__list__section {
    width: 280px;
  }
  .avatar__list__sub__section {
    width: 260px;
    height: 130px;
  }
  .avatar__list__sub__sub__section::after {
    width: 100px;
    left: 6rem;
  }
  .avatar__list__section__image {
    width: 100px;
    height: 110px;
  }
  .avatar__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  #root
    > div
    > div
    > main
    > main
    > div.main-content
    > div
    > div:nth-child(1)
    > div
    > div.content-tabs
    > div.content.active-content {
    padding: 40px 0 0;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
/* @media only screen and (max-width: 500px) {
  .avatar__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
} */

@media only screen and (max-width: 360px) {
  .avatar__list__section__image {
    width: 110px;
    height: 170px;
  }
  .avatar__list__section__imag {
    height: 100%;
    width: 100%;
  }
}

/* @media only screen and (max-width: 300px) {
  .avatar__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
} */

.giftcard-main {
  position: relative;
}

.giftcard-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #1b1c1d;
  overflow-x: hidden;
}

.gift-item {
  height: 39.3vh;
  margin: 0 0.125rem 0.5rem;
  padding: 1.5rem 1rem 0;
}

.gift-item a {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  text-decoration: none;
}

.gift-item header {
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
}

.gift-item header h4 {
  font-size: 1rem;
  line-height: 1.875rem;
  color: #ffffff;
}

.gift-item-content {
  align-self: center;
  justify-self: center;
  width: 156px;
  height: 93px;
}
.gift-item-content img{
  width: 100%;
  height: 100%;
}

.giftcard-main .cta-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(27, 28, 29, 0.902);
  z-index: 999;
  opacity: 1;
  display: block;
  visibility: visible;
  transition: opacity 0.4s ease-out;
  -moz-transition: opacity 0.4s ease-out;
  -webkit-transition: opacity 0.4s ease-out;
}

.cta-overlay.clear-overlay {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.giftcard-main .cta-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* grid-template-rows: 55vh 45vh;
  place-items: center; */
  height: 100vh;
  color: #fdfdfd;
}

.giftcard-main .cta-info {
  text-align: center;
}

.giftcard-main .cta-info p {
  margin-bottom: 50px !important;
  font-family: "Airbnb Cereal App Medium";
  color: #fdfdfd;
  font-size: 1rem;
}

.giftcard-main .cta-btn {
  color: #fdfdfd;
  border: 1px solid #fdfdfd;
  padding: 0.75rem 4rem;
  outline: none;
  font-family: "Airbnb Cereal App Medium";
  font-size: 1rem;
}

.giftcard-main .cta-btn:focus {
  border: 2px solid #fdfdfd;
}

@media only screen and (min-width: 600px) {
  .giftcard-content {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  .gift-item header h4 {
    font-size: 1.75rem;
    font-family: "Airbnb Cereal App Bold";
  }
  .giftcard-main .cta-info p {
    font-size: 1.25rem;
  }

  .gift-item {
    height: 49.3vh;
  }
  .gift-item-content {
    align-self: center;
    justify-self: center;
    width: 200px;
    height: 120px;
  }
}

@media only screen and (min-width: 992px) {
  .gift-item:first-child,
  .gift-item:nth-child(3),
  .gift-item:nth-child(5) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1368px) {
  .gift-item:nth-child(3),
  .gift-item:last-child {
    margin-right: 0;
  }

  .gift-item:first-child,
  .gift-item:nth-child(4) {
    margin-left: 0;
  }

  .gift-item:nth-child(4),
  .gift-item:nth-child(5),
  .gift-item:last-child {
    margin-bottom: 0;
  }

  .gift-item:nth-child(3),
  .gift-item:nth-child(5) {
    margin-left: 0.125rem;
  }
}

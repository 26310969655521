/* .savedbank {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 70px 0px;
  flex-wrap: wrap;
} */
.savedbank {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  margin: auto;
  align-items: center;
  padding: 100px 30px;
  overflow-x: auto;
  flex-wrap: nowrap;
  width: 1200px;
}
.savedbank__info,
.savedbank__addbank {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.savedbank__add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.savedbank__add img {
  width: 86px;
  height: 86px;
}
.number__div {
  width: 34px;
  height: 34px;
}
.number__div img {
  width: 100%;
  height: 100%;
}
.savedbank__add p {
  color: var(--button-color);
  font-family: "Airbnb Cereal App Book";
  padding-top: 7px;
  font-size: 0.875rem;
}
.savedbank__info {
  gap: 40px;
  padding: 20px 10px;
}
.savedbank__carddetails {
  padding: 40px 34px;
  background-color: #f8f8f8;
  border: 1px solid #efefef;
  border-radius: 10px;
  width: 450px;
  height: 200px;
  position: relative;
}
.savedbank__carddetails__info {
  position: absolute;
  top: 80px;
  left: 90px;
}
.savedbank__carddetails__info p {
  color: #1B1C1D;
  font-family: "Airbnb Cereal App Bold";
  font-size: 1.25rem;
}
.savedbank__carddetails img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .savedbank__carddetails__info p {
    font-size: 1rem;
  }
  .savedbank__carddetails {
    padding: 20px;
    width: 280px;
    height:150px;
  }
  .savedbank__info {
    gap: 30px;
  }
  .savedbank {
    padding: 50px 0px;
  }
  .savedbank__add {
    padding-top: 10px;
  }
  .savedbank__add img {
    width: 56px;
    height: 56px;
  }
  .number__div {
    width: 25px;
    height: 25px;
  }
  .savedbank__carddetails__info {
    position: absolute;
    top: 60px;
    left: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .savedbank {
    padding: 50px 0px;
    grid-template-columns: repeat(1, 1fr);
    width:300px;
    /* grid-gap: 0.5rem; */
  
  
    
    /* flex-wrap: nowrap; */
    /* width: 1200px; */
  }
}

@media screen and (max-width: 280px) {
  .savedbank__carddetails {
    padding: 15px;
    width: 250px;
  }
  .savedbank__carddetails__info {
    top: 40px;
    left: 65px;
  }
  .savedbank__carddetails__info p {
    font-size: 1rem;
  }
}

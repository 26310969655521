.home-header .dropdown-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px #00000029;
  width: 18.875rem;
  height: 0;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  display: grid;
  grid-template-rows: repeat(2, auto) 1fr;
}

.home-header .show-dropdown {
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.home-header .dropdown_header {
  padding: 0;
  border-bottom: 1px solid #EFEFEF;
}

.dropdown-header_top > div:first-child {
  background-image: url("../../../asset/Mask\ Group\ sm.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 4.625rem;
  padding: 0.625rem 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.dropdown-header_top > div:first-child button {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1B1C1D80;
  border-radius: 50%;
}
.user-profile a,
.user-account a{
  text-decoration: none;
}
.user-profile-span{
  font-family: "Airbnb Cereal App Book";
}
.profile_img-wrapper {
  transform: translateY(50%);
  width: 67px;
  height: 67px;
}
.profile_img-wrapper img{
  border-radius: 50%;
}

.dropdown-profile {
  text-align: center;
  color: #1B1C1D;
  padding: 2rem 0 1rem;
}

.dropdown-profile p {
  font-family: "Airbnb Cereal App Medium";
  font-size: 0.875rem;
  line-height: 1.875rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
}

.dropdown-profile a {
  font-family: "Airbnb Cereal App Medium";
  padding: 0.625rem 2rem;
  border-radius: 10px;
  border: 1px solid #1B1C1D;
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: bold;
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.dropdown_content .user-profile,
.dropdown_content .user-account {
  padding: 1.125rem 1rem;
  border-bottom: 1px solid #EFEFEF;
}

.home-header .dropdown_content {
  line-height: 1.375rem;
}

.home-header .dropdown_content h4 {
  font-family: "Airbnb Cereal App Medium";
  font-size: 1rem;
  color: #1B1C1D;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.home-header .dropdown_content li {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  color: #6F6F6F;
  font-weight: 500;
  margin-bottom: 8px;
}

/* .home-header .dropdown_content li:last-child {
  margin-bottom: 0;
} */

.dropdown_footer {
  padding: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.dropdown_footer button {
  background-color: #F5F5F7;
  text-transform: capitalize;
  padding: 0.625rem 1.2rem;
  border: none;
  font-weight: 600;
  color: #1B1C1D;
}

.home-header .dropdown_footer button > span {
  margin-left: 0.5rem;
  font-family: "Airbnb Cereal App Medium";
}
.user-username{
  text-transform: capitalize;
}

@media only screen and (min-width: 600px) {
  .home-header .dropdown-container {
    position: absolute;
    top: 3.5rem;
    left: 0;
    border-radius: 10px;
  }

  .home-header .show-dropdown {
    height: auto;
  }

  .dropdown-header_top > div:first-child button {
    display: none;
  }

  .dropdown-profile {
    /* padding: 0.625rem 0; */
    padding: 2rem 0 1rem;
  }

  .home-header .dropdown_content .user-profile,
  .home-header .dropdown_content .user-account {
    padding: 0.625rem 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .profile_img-wrapper {
    width: 4.375rem;
    height: 4.375rem;
  }

  .dropdown-profile a {
    font-size: 0.875rem;
    padding: 0.75rem 2rem;
  }

  .dropdown_footer button {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

@media only screen and (min-width: 992px) {
  .home-header .dropdown-container {
    left: -110%;
    width: 20.24rem;
  }
}
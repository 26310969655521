.withdraw__section__main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 50px auto;
  padding: 40px;
  border: 1px solid #efefef;
  width: 90%;
}
.saved__withdraw__banks__carddetails__bank__info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #efefef;
  border-radius: 7px;
  padding: 15px;
  margin: 20px 10px;
}
.saved__withdraw__banks__carddetails__bank__info__image {
  margin-bottom: 10px;
  height: 35px;
  width: 35px;
}
.saved__withdraw__banks__carddetails__bank__info__image img {
  height: 100%;
  width: 100%;
}
.withdraw__bank__details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 80%;
  /* width: 818px; */
  height: auto;
  /* background-color: #f8f8f8; */
  border: 1px solid #efefef;
  border-radius: 10px;
  margin: 15px auto 20px;
  padding: 30px 0px 40px;
}
.withdraw__bank__details__text__section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  /* width: 818px; */
  width: 80%;
  margin: 40px auto 0px;
  gap: 40px;
  /* border: 3px solid red; */
  /* text-align: left!important; */
}
.withdraw__bank__details__text__section a,
.withdraw__bank__details__text__section a:visited,
.withdraw__bank__details__text__section a:hover,
.withdraw__bank__details__text__section a:active {
  text-decoration: none;
  color: #6c63ff;
}
.withdraw__bank__details__text {
  font-size: 1.25rem;
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  /* margin-bottom: 0!important; */
  /* width: 818px; */
}
.withdraw__bank__details__add__bank__section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.withdraw__bank__details__add__bank__image {
  margin-top: -15px;
  height: 20px;
  width: 20px;
}
.withdraw__bank__details__add__bank__image img {
  width: 100%;
  height: 100%;
}
.withdraw__bank__details__add__bank__text {
  font-size: 1rem;
  color: #6c63ff;
  font-family: "Airbnb Cereal App Bold";
  /* margin-bottom: 0!important; */
}
.input__amount__and__proceed {
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-gap: 1rem;
  /* width: 818px; */
  width: 80%;
  margin: 10px auto;
  align-items: center;
}
/* .withdraw__details__section__form-group {

} */
.withdraw__details__section__account-label {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  font-size: 1rem;
}
.withdraw__details__section__account-label span {
  font-size: 0.75rem;
  margin-left: 10px;
  font-family: "Airbnb Cereal App Bold";
}

.withdraw__details__section__input {
  width: 100%;
  display: block;
  padding: 0.625rem 1.25rem 0.625rem 0.8rem;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  color: #1b1b1b;
  font-family: "Airbnb Cereal App Medium";
  height: 60px;
}
.withdraw__details__section__form-group {
  margin-bottom: 15px;
}
.withdraw__details__section__button__section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.withdraw__details__section__button {
  color: var(--main-white);
  text-align: center;
  font-size: 1rem;
  padding: 20px 30px;
  font-family: "Airbnb Cereal App Book";
  background-color: var(--button-color);
  border-radius: 10px;
  margin-top: 25px;
  width: 219px;
}
.withdraw__bank__details__text__section {
  flex-direction: column;
}
@media only screen and (min-width: 1200px) {
  .input__amount__and__proceed {
    grid-template-columns: 489px 1fr;
  }
}
@media only screen and (max-width: 992px) {
  .withdraw__bank__details {
    width: 100%;
  }
  .withdraw__bank__details__text {
    font-size: 1rem;
    margin-bottom: 0;
  }
  .input__amount__and__proceed {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .withdraw__details__section__button__section {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .withdraw__bank__details__text__section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 30px auto 0px;
    /* flex-direction: column; */
    gap: 20px;
  }
}

@media (max-width: 900px) {
  .withdraw__section__main {
    margin: 40px 40px;
    padding: 40px;
  }
  .withdraw__details__section__form-group {
    margin-bottom: 20px;
  }
}
@media (max-width: 500px) {
  .withdraw__section__main {
    margin: 40px 30px;
    padding: 40px 10px 20px;
  }
  .princess__jewel {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
  .withdraw__details__section__account-label span {
    margin: 5px 0px 0px 5px;
  }
}

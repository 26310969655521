.accordion {
width:85%;
}
.accordion-inner{
 transition:all 0.4s ease-out;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  color: #1b1c1d;
  font-size: 1.5rem;
  font-family: "Airbnb Cereal App Bold";
  padding: 1.5rem  ;
  text-align: left;
}
.accordion-item{
    margin-bottom: 30px;
    border: 1px solid #DCE0E8!important;
    border-radius: 10px!important;
}
.accordion-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  color: #868593;
  padding: 0rem 2rem 1rem;
  line-height: 2;
  font-family: "Airbnb Cereal App Book";
  font-size: 0.875rem;
 
}

@media screen and (max-width: 767px) {
  .accordion {
    width: 90%;
  }
  .accordion-title {
    padding: 2rem;
  }
  .accordion {
    /* max-width: 1396px; */
    margin: 2rem auto 10rem;
  }
}

@media screen and (max-width: 420px) {
    .accordion-title {
       font-size: 1rem;
      }
      .accordion-title {
        padding: 1.5rem 1rem;
      }
      .accordion-content {
        font-size: 0.875rem;
        padding: 0rem 1rem 1rem;
      }
      .accordion {
        margin: 2rem auto 7rem;
      }
}

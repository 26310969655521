.wallet-balance {
  _flex: 3;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 450px;
  padding:1.2rem 0.8rem 2rem;
}

.king-img {
  width: 20%;
}

.withdraw-btn {
  background-color:#fff ;
  color: rgb(27, 28, 29)!important;
}
.withdraw-btn a,
.withdraw-btn a:visited,
.withdraw-btn a:active{
  background-color: rgb(27, 28, 29);
  color: #fff!important;
  text-decoration: none;
  font-family: "Airbnb Cereal App Bold";
}

.trade__btn ,
.trade__btn:visited,
.trade__btn:active{
  background-color: rgb(27, 28, 29);
  color: #fff!important;
  text-decoration: none;
  font-family: "Airbnb Cereal App Bold";
}

.withdraw-btn:hover{
  background-color: rgb(27, 28, 29);
  color: #fff !important ;
}
.trade__btn:hover {
  background-color: #fff;
  color: rgb(27, 28, 29)!important ;
}

.wallet-balance .card-body {
  text-align: center;
}

.wallet-balance h4,
.wallet-balance .card-body p {
  font-size: 1rem;
  line-height: 1.875rem;
  /* font-weight: 600; */
  color: #a8a8a8;
  margin-bottom: 0.5rem;
  font-family: "Airbnb Cereal App Medium";
}

.wallet-balance .card-body p {
  line-height: 1.5rem;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
}

.card-body p.wallet-amount {
  font-size: 2rem;
  line-height: 1.875rem;
  font-weight: bold;
  color: rgb(27, 28, 29);
}

@media only screen and (min-width: 1100px) {
  .wallet-balance {
    _flex: 3;
  }
}

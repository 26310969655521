
.balance {
  position: relative;
  _flex: 3;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-image: url("../../asset/Group-164629.png");
  /* background-image: url("../../asset/Group-162735.png"); */
  background-color: #cccccc;
  height: 300px;
  width: 570px;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
}
.countryflag {
  width: 20%;
  position: absolute;
  right: 0;
  bottom: 20px;
}

.balance .card-body {
  text-align: left;
}

.balance h4,
.balance .card-body p {
  font-size: 1rem;
  line-height: 1.875rem;
  color: #a8a8a8;
  margin-bottom: 0.5rem;
  font-family: "Airbnb Cereal App Medium";
  margin: 1.2rem 0 0 2.8rem;
}

.balance .card-body p {
  line-height: 1.5rem;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
}

p.wallet-amount {
  font-size: 2rem;
  line-height: 1.875rem;
  font-weight: bold;
  color:#FDFDFD;
  margin: 1.2rem 0 0 2.2rem;
  font-family: "Airbnb Cereal App Bold";
}

@media only screen and (min-width:768px){
  .countryflag {
    bottom: 30px;
  }
}
@media only screen and (min-width: 1100px) {
  .balance {
    _flex: 3;
  }
}
@media only screen and (max-width: 1200px){
  .balance {
    width: 100%;
   /* margin-right:50px; */
  }
}

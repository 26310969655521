.avatar-bloc-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #efefef;
  border-radius: 5px;
  height: 60px;
  width: 60%;
  margin: auto;
}
.avatar-tabs {
  padding: 8px 10px !important;
  text-align: center;
  width: 40%;
  background-color: #efefef;
  cursor: pointer;
  border: none;
  box-sizing: content-box;
  position: relative;
  outline: none;
  white-space: nowrap;
  -webkit-transition: background-color 0.3s ease-in;
  -moz-transition: background-color 0.3s ease-in;
  transition: background-color 0.3s ease-in;
}

  
.active-avatar-tabs {
  background: #fdfdfd;
  border-radius: 3px;
  transition: 0.3s;
}

.active-avatar-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
}

.content-avatar-tabs {
  flex-grow: 1;
}
.avatar-content {
  background: white;
  padding: 70px 30px 0px;
  width: 100%;
  height: 100%;
  display: none;
  text-align: center;
}
.avatar-bloc-tabs button {
  color: #1b1c1d;
  font-size: 1rem;
  font-family: "Airbnb Cereal App Medium";
}
.active-avatar-content {
  display: block;
}
.avatar__main {
  /* width: 90%; */
  padding: 40px;
}
.redem__avatar__top{
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start;
  align-items: flex-start; */
}
/* .redem__avatar__top__title{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  color:#A8A8A8;
  font-size: 1rem;
} */

@media only screen and (max-width: 991px) {
  .avatar__main {
    padding: 40px 20px;
  }
  #root
    > div
    > div
    > main
    > main
    > div.main-content
    > div
    > div:nth-child(1)
    > div
    > div.content-avatar-tabs
    > div.avatar-content.active-avatar-content {
    padding: 40px 10px 0px;
  }
}
@media only screen and (max-width: 768px) {
  .avatar__main {
    padding: 25px 10px 40px;
  }
}
.profile-img-top {
  display: flex;
  justify-content: flex-end;
  height: 4.625rem;
  background-image: url("../../../asset/Mask Group lg.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  position: relative;
  padding: 0.625rem;
  margin-bottom: 2rem;
  border-radius: 10px 10px 0 0;
}

.profile-img-top img {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  position: absolute;
  bottom: -25%;
  left: 50%;
}

.profile-header .btn-container {
  padding: 0.875rem 0;
  text-align: center;
}

.profile-header .btn-container button {
  background-color: transparent;
  
}
.delete-btn{
  border: 1px solid red!important;
  }

.profile-header .btn-container > button:first-child {
  margin-right: 1rem;
  font-family: "Airbnb Cereal App Medium";
}

.profile-header p {
  display: none;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #1b1b1b;
  padding: 0.625rem 1rem;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
}

@media only screen and (min-width: 480px) {
  .profile-img-top {
    height: 7.5rem;
  }
}

@media only screen and (min-width: 600px) {
  .profile-header p {
    display: block;
  }

  .profile-img-top {
    padding: 1rem;
    margin-bottom: 0;
  }

  .profile-img-top img {
    width: 6.25rem;
    height: 6.25rem;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
    left: 10%;
  }
}

@media only screen and (min-width: 680px) {
  .profile-img-top {
    height: 10.625rem;
  }
}

@media only screen and (min-width: 992px) {
  .profile-header .btn-container {
    text-align: right;
  }
}
.hottestlistcontent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 6.25rem;
}
#root > div > div > main > main > div > section > ul > div:nth-child(n) > div.card-cont > div:nth-child(n) > div > a{
text-decoration: none;
}
.card-name-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.card-name {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Book";
  font-size: 0.875rem;
  text-align: center;
}
.card-home img {
  width: 100%;
  height: 100%;
}
.card-name-bold {
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
  font-size: 0.875rem;
}
.card-home {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 18px;
  height: 18px;
}
.card-house {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto 20px;
  width:83px;
  height: 50px;
}
.card-house img{
  width: 100%;
  height: 100%;
}
.card-con {
  position: relative;
  padding: 0.8rem !important;
}
.card-cont {
  margin-top: 30px;
}

.btn-btn {
  font-size: 0.75rem;
  width: 74px;
  height: 25px;
  color: #1b1c1d;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Airbnb Cereal App Bold";
}

@media (min-width: 767px) {
  .card-house {
    width:140px;
    height: 81px;
  }
  .hottestlistcontent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 0;
  }
  .card-name-footer {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
  }
  .card-name {
    font-size: 0.93rem;
    text-align: left;
  }
  .card-name-bold {
    font-size: 1.25rem;
  }
  .card-con {
    position: relative;
    padding: 1.5rem !important;
  }
}

@media (min-width: 1400px) {
  .hottestlistcontent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
  }

  .card-name {
    font-size: 0.875rem;
    text-align: left;
  }
  .card-home {
    position: absolute;
    right: 16px;
    top: 12px;
  }
  .card-name-bold {
    font-size: 1.25rem;
  }
}

.tags-input-container {
  border: 1px solid #D3D3D3;
  padding: 10px;
  border-radius: 5px;
  width: min(80vw, 600px);
  margin: 0.5em auto 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.tag-item {
  background-color: #EFEEFF;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
}

.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: #6C63FF;
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  padding: 0 1px 3px;
  cursor: pointer;
}
.tags-input{
    flex-grow: 1;
    padding: .5em;
    border:none;
    outline: none;
    background-color: transparent;
    color: #fff;
}
.tags-input::placeholder{
    color: #fff;
}

.notification__main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 100px auto;
  /* max-width: 88%; */
  width: 88%;
}
.notification__main__section {
  margin: 10px;
  border-bottom: 1px solid #1b1c1d;
  padding: 0 0 10px;
}
.notification__main__section__sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
}
.notification__main__section__title {
  margin-left: -5px;
  font-size: 1.375rem;
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Bold";
}
.notification__main__section__date {
  font-size: 0.875rem;
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Book";
  white-space: nowrap;
}
.notification__main__section__body {
  font-size: 1rem;
  color: #1b1c1d;
  font-family: "Airbnb Cereal App Book";
}

@media only screen and (max-width: 767px) {
  .notification__main {
    margin: 20px auto 40px;
    width: 100%;
    padding: 20px;
  }
  .notification__main__section__body,
  .notification__main__section__title {
    font-size: 0.875rem;
  }
}
